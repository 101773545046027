import { t } from "i18next";

const ContractFields = [
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "ContractCode",
    FieldType: "bigint",
    FieldTitle: t("Code"),
    Position: 1,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    FieldName: "Reference",
    FieldType: "varchar",
    FieldTitle: t("Reference"),
    cssClasss: "col-12 col-md-8",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },

  {
    IdAppForm: 62,
    Id: 6200002,
    FieldName: "IdMarketSegment",
    FieldType: "bigint",
    FieldTitle: t("Market Segment"),
    Position: 2,
    cssClasss: "col-12",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"295e14da5b9cdb270caead127ecc4aa64b0e70d32b8e161908da154aa3ae7968"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200003,
    FieldName: "StartDate",
    FieldType: "datetime",
    FieldTitle: t("Start Date"),
    Position: 3,
    cssClasss: "col-12 col-md-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200004,
    FieldName: "EndDate",
    FieldType: "datetime",
    FieldTitle: t("End Date"),
    Position: 4,
    cssClasss: "col-12 col-md-6",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    minValueDependOf: "StartDate",
  },
  {
    FieldName: "Description",
    FieldType: "varchar",
    FieldTitle: t("Description"),
    cssClasss: "col-12 col-md-12",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },

  {
    IdAppForm: 62,
    Id: 6200006,
    FieldName: "Comments",
    FieldType: "varchar",
    FieldTitle: t("Comments"),
    Position: 6,
    cssClasss: "col-12 col-md-4",
    HtmlType: "textarea",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200016,
    FieldName: "IdContractStatus",
    FieldType: "int",
    FieldTitle: t("Status"),
    Position: 8,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: null,
    //  "DefaultValue": 1,
    OptionValues: null,
    DataSource:
      '{"HashID":"dcd725c8de4a7a0ea1c83801bb3a0ac523cfca2d02bddbe1e96d14b243de0391"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200013,
    FieldName: "IdCurrency",
    FieldType: "int",
    FieldTitle: t("Currency"),
    Position: 9,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"637f44b9bafa1fe74b0a72c887b1c3262c833080fea07d40768858638f0fc87b"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    // dependOf: "IdMarketSegment",
    // value: 1,
  },
  {
    FieldName: "IdMealPlan",
    FieldType: "bigint",
    FieldTitle: t("Meal Plan"),
    Position: 6,
    cssClasss: "col-12 ",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"4bf2a611eaf1b54c557e672a9976de2d180972861db45c396f5a7c95f2819576"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];

export default ContractFields;
