export const RoomMapFilterFields = [
  {
    "FieldName": "IdBusinessUnit",
    "FieldType": "bigint",
    "FieldTitle": "Business Unit",
    "HtmlType": "select",
    "cssClasss": "col-md-3",
    "Visible": true,
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": "{\"HashID\":\"be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef\"}",
    "Required": true
  }
]