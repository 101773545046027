import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useEffect, useState } from "react";
import { Container, Modal, Row } from "react-bootstrap";
import API from "../../../api/api";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { folioReport } from "./reports/folio-2.report";
import { useToast } from "../../../contexts/toast";
import { MasterFolioReport } from "./reports/master-folio.report";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};

const PrintMasterFolio = (props) => {
  const user = parsedUser();
  const toast= useToast();
  const [url, setUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const bindDataRemote = async () => {
    setIsLoading(true);
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryData = {
        Data: `@GUIDReservationToFolio='${props.selectedFolio.GUIDReservationToFolio}'`,
      };

      let request = await API.postAction(
        `api/cashier/PrintFolio?${query}`,
        queryData
      );
      console.log(JSON.parse(request.data[0]?.JSONData)[0]);
      const folioHeader = JSON.parse(request?.data[0]?.JSONData)[0].JSONData[0];
      const folioDetails = JSON.parse(request?.data[0]?.JSONData)[0]
        .JSONDataDet;
      if (!folioHeader || !folioDetails) {
        return;
      }
      const docDefinition = MasterFolioReport(folioHeader, folioDetails);
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBlob((blob) => {
        setUrl(URL.createObjectURL(blob));
      });
    } catch (error) {
      console.log(error);
      toast({
        type: "error",
        message: "An error has occurred",
      })
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bindDataRemote();
  }, [props.selectedReservation]);

  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
      size={"xl"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Print Master Folio</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{height: '85vh'}}>
        <Container fluid className="overflow-auto table-responsive">
          <Row>
            {url ? (
              <iframe src={url} width="100%" style={{minHeight: '80vh'}} title="Masterfolio PDF"></iframe>
            ) : (
              <div
                className="text-center"
                style={{ width: "100%", height: "550px" }}
              >
                {isLoading ? <h4>Loading...</h4> : <h4>No records found</h4>}
              </div>
            )}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default PrintMasterFolio;
