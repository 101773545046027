import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Search,
  DeleteForever,
} from "@mui/icons-material";
import "devextreme/data/odata/store";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Card, Col, Container, Dropdown, Form, Modal, Pagination, Row, Table } from "react-bootstrap";
import * as yup from "yup";

import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useLocation, useParams } from "react-router-dom";
import { TextBox } from "devextreme-react";
import FieldSelector from "../../components/customComponents/FieldSelector";
import { useFormik } from "formik";
import Sha256 from "../../utils/sha256";

import { useToast } from "../../contexts/toast";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { exportToPDF as GeneratePDF } from '../../utils/export-to-pdf';
import { exportToExcel as GenerateXLS } from '../../utils/export-to-excel';
import { CustomBreadcrumbs as Breadcrumbs } from "../../components";
import useFavorite from "../../hooks/useFavorite";
import moment from "moment";

export default function CRUDSimple() {
  // PROPERTIES
  const user = parsedUser();
  const { pathname } = useLocation();
  const params = useParams();
  const { idModule } = useParams();
  const toast = useToast();

  const [recordCount, setRecordCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [generalError, setGeneralError] = useState(null);
  const [open, setOpen] = useState(false);
  const [actionMode, setActionMode] = useState(null);//I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  // const [isPinned, setIsPinned] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const checkAudit = localStorage.getItem('AuditInformation')

  const initialModel = {
    section: {},
    data: {
      Columns: [],
      Rows: [],
    },
  }
  const [section, setSection] = useState(null);
  const [fields, setFields] = useState([])
  const [model, setModel] = useState(initialModel);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(20);
  const allowedPageSizes = [{ value: 10, text: 10 }, { value: 20, text: 20 }, { value: 50, text: 50 }, { value: 100, text: 100 }, { value: 0, text: "All" }];
  const [selectedItem, setSelectedItem] = useState(null);
  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite()
  const selectedItemRef = useRef(null);
  const [sortBy, setSortBy] = useState('Id')
  const [sortByOrder, setSortByOrder] = useState('DESC')
  // END PROPERTIES



  //METHODS
  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRemote = useCallback(async (cleanFilter) => {

    // First start loading the model
    setLoading(true);

    //First load records

    try {
      let query = `IdUser=${user.IdUser}&id=${params.id}&token=${user.Token}${idModule ? "&IdModule=" + idModule : ""}`;
      if (search.length > 0 && cleanFilter == null) {
        query += "&Search=" + search;

      }
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let quantity = RecordsQuantity === 0 ? recordCount : RecordsQuantity;
      query = query + `&Quantity=${quantity}&pageNumber=${currentPage}`
      let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.getAction(`api/${prefix}/Records`, query);
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData || [];
      // let configInfo = recordResult[0].JSONConfig ? recordResult[0].JSONConfig[0] : null;
      let dataFromAPI = recordResult[0] ? (recordResult[0].JSONConfig ? recordResult[0].JSONData : recordResult) : [];
      console.log("Results from record", recordResult);


      //TODO: | Use JSONConfig to show specific fields
      let gridData = null;
      let gridColumns = dataFromAPI && dataFromAPI.length > 0 ? Object.keys(dataFromAPI[0]).filter(x => !x.startsWith("Id")) : null;

      //! If grid data stays null, we use the data from the API

      let titles = ['Audit Information', 'Added by', 'Date Added', 'Modified by', 'Last Modified']

      setModel({
        ...model,

        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: gridData || dataFromAPI,
        },
      });



    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, search, sortBy, sortByOrder, RecordsQuantity, params.id, idModule]);


  useEffect(() => {
    setTableHeader([])
    bindDataRemote();
    setSelectedItem(null);
    bindDataCountRemote();
  }, [params.id, idModule])

  useEffect(() => {
    bindDataRemote();
    setSelectedItem(null);
  }, [currentPage])

  const bindDataCountRemote = async (cleanFilter) => {

    // First start loading the model
    //setLoading(true);

    //First load records

    try {
      let query = `IdUser=${user.IdUser}&id=${params.id}&token=${user.Token}${idModule ? "&IdModule=" + idModule : ""}`;
      if (search.length > 0 && cleanFilter == null) {
        query += "&Search=" + search;

      }

      query = query + `&Quantity=${RecordsQuantity}&pageNumber=${currentPage}`
      let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.getAction(`api/${prefix}/RecordsCount`, query);


      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecordCount(parsedResults.JSONData.Count);
      console.log("Record Count that comes from api ", parsedResults.JSONData.Count)


    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  const bindDataModelRemote = async (cleanFilter) => {


    try {

      let prefix = idModule ? "CRUDDynamic" : "CRUD";

      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&id=${params.id}&token=${user.Token}${idModule ? "&IdModule=" + idModule : ""}`;
      let requestSections = await API.getAction(`api/${prefix}/Model`, querySections);
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;

      //! If grid data stays null, we use the data from the API

      let titles = ['Audit Information', 'Added by', 'Date Added', 'Modified by', 'Last Modified']
      console.log(recordResultSection)
      setSection(recordResultSection[0]);
      setFields(recordResultSection[0].Fields.filter((item) => !titles.includes(item.FieldTitle)));

    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };
  const [formSize, setFormSize] = useState('md');
  const [tableHeader, setTableHeader] = useState([]);
  useEffect(() => {

  })
  const bindDataHeaderRemote = async (cleanFilter) => {


    try {

      let prefix = idModule ? "CRUDDynamic" : "CRUD";

      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&id=${params.id}&token=${user.Token}${idModule ? "&IdModule=" + idModule : ""}`;
      let requestSections = await API.getAction(`api/${prefix}/Header`, querySections);
      console.log("Header", requestSections)
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      console.log("Header 2", parsedResultsSections)
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;
      console.log(recordResultSection);
      setFormSize(recordResultSection[0].FormSize ?? 'md');

      //! If grid data stays null, we use the data from the API

      let titles = ['Audit Information', 'Added by', 'Date Added', 'Modified by', 'Last Modified']
      console.log(recordResultSection)

      setTableHeader(recordResultSection[0].Fields)

    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };

  useEffect(() => { bindDataRemote() }, [checkAudit])

  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    try {
      let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let query = `IdUser=${user.IdUser}&id=${params.id}&token=${user.Token}&RecordId=${recordId}${idModule ? "&IdModule=" + idModule : ""}`;
      let request = await API.getAction(`api/${prefix}/Details`, query);
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }

      let finalResult = parsedResults.JSONData;
      fillFormikObject(finalResult);
      setSelectedItem(null)
    } catch (error) {
      console.error(error)
    }
    //Fill object with new values
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedItem(null);
      formik.resetForm();
    }

    if (action === "I") {
      setSelectedItem(null)
    }
    if (action === "U" || action === "C" || action === "D") {
      setOpen(!open);

      await bindDataDetailsRemote(selectedItem.GUID);
    }

    if (selectedItemRef.current) {
      selectedItemRef.current.clearSelection();
    }

    setGeneralError(null);
    setOpen(!open);
    if (reload) {
      bindDataRemote();
    }
  };

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {

            let prefix = idModule ? "CRUDDynamic" : "CRUD";
            let query = `IdUser=${user.IdUser}&id=${params.id}&token=${user.Token}&RecordId=${selectedItem.GUID}${idModule ? "&IdModule=" + idModule : ""}`;
            await API.getAction(`api/${prefix}/Delete`, query);
            await bindDataRemote();
            setSelectedItem(null);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };



  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    // console.log("record", record, "model", section);
    if (
      section &&
      fields &&
      fields.length > 0
    ) {
      fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    // console.log("Preparing formik object", section)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    console.log("❗ ~ file: CRUDSimple.js:259 ~ handleSubmit ~ obj:", obj)
    console.log({ fields: fields })
    let actions = {
      "I": "I",
      "U": "U",
      "D": "I"
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }


    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to save this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let objSPVersion = "";
            fields.forEach((item) => {

              if (item.ReadOnly || item.HtmlType === "separator") return;

              if (item.HtmlType !== "separator") {
                objSPVersion +=
                  (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
              }
              if (
                item.FieldType === "int" ||
                item.FieldType === "bool" ||
                item.FieldType === "bit" ||
                item.FieldType === "bigint"
              ) {
                objSPVersion +=
                  obj[item.FieldName] === undefined || obj[item.FieldName] === null
                    ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                    : "'" + obj[item.FieldName] + "'";

              } else if (item.HtmlType === "password") {
                let passwordEncrypted = null;
                if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
                  passwordEncrypted =
                    obj["UserName"].toUpperCase() + obj[item.FieldName];
                  passwordEncrypted = Sha256.hash(passwordEncrypted);
                  ;
                }
                objSPVersion +=
                  obj[item.FieldName] === undefined
                    ? "null"
                    : "'" + passwordEncrypted + "'"
              } else
                if (item.HtmlType === 'radio') {

                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                      : "'" + obj[item.FieldName] + "'";
                } else {
                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? "null"
                      : "'" + obj[item.FieldName] + "'";
                }
            });

            let queryString = `IdUser=${user.IdUser}&Id=${params.id}&Token=${user.Token}&ActionMode=${actions[actionMode]}${idModule ? "&IdModule=" + idModule : ""}`;
            let queryData = {
              Data: objSPVersion,
            };

            let prefix = idModule ? "CRUDDynamic" : "CRUD";
            let request = await API.postAction(
              `api/${prefix}/AddEdit?${queryString}`,
              queryData
            );

            if (request.status === 200) {
              let response = JSON.parse(request.data[0].JSONData)[0];
              debugger;
              if (response.Error) {
                setGeneralError(response.Msg);
                return;
              }
            }
            setGeneralError(null);
            setSelectedItem(null);

            formik.resetForm();

            toast({
              type: "success",
              "message": "Record saved successfully",
            })
            toggleOpen(null, true);
          },
        },
        {
          label: "No",
        },
      ],
    });


  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });


  const addFavorite = async () => {

    const newFavorite = {
      icon: `${section.FormIcon}`,
      path: pathname,
      formPath: section.FormPath,
      title: section.FormTitle,
    }
    UpdateFavorites(newFavorite)

  }

  const headerWithoutAuditFields = () => {
    return tableHeader && tableHeader
      .filter(x =>
        x.FieldTitle !== "Audit Information"
        && x.FieldName !== "Id"
        && x.FieldName !== "IdUserAdd"
        && x.FieldName !== 'DateAdd'
        && x.FieldName !== 'IdUserMod'
        && x.FieldName !== 'DateMod')
  }

  const exportToExcel = useCallback(async () => {
    GenerateXLS(model.data.Rows, `${section.FormTitle}.xlsx`);
  }, [model.data.Rows])

  const exportToPDF = useCallback(async () => {
    const container = document.getElementById("contentContainer");
    GeneratePDF(container);
  }, [])

  // END METHODS

  // UI METHODS

  useEffect(() => {
    console.log("entro", new Date())
    bindDataRemote();
  }, [sortBy, sortByOrder])

  const loadGRIDUI = () => {
    return (
      <>
        {isSearching && (
          <Card className="mb-4">
            <Card.Header className="w-full d-flex justify-content-between align-items-center">
              <Card.Title>Filters</Card.Title>

              <button
                className="btn btn-secondary"
                title="Close"
                type="button"
                onClick={() => { setIsSearching(false) }}
                style={{
                  "padding": "0px 8px",
                  "lineHeight": "23px"
                }}
              >
                <i style={{ fontSize: "10px" }} className="fa-solid fa-x"></i>
              </button>
            </Card.Header>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                await bindDataRemote();
              }}
            >
              <Card.Body className="formBackground p-0">
                <Row>
                  <Col className={"col-12"}>
                    <TextBox
                      // className="form-control "
                      id={"search"}
                      mode={"text"}
                      value={search}
                      placeholder="Search by description"
                      onValueChanged={(e) => {
                        setSearch(e.value);
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <button
                  className="btn btn-sm btn-outline-secondary me-2"
                  type="button"
                  title="Clear"
                  onClick={() => {
                    setSearch("");
                    setTimeout(async () => {
                      await bindDataRemote(true);
                    }, 1000);
                  }}
                >
                  <i className="fa-solid fa-filter-circle-xmark" style={{ fontSize: '1.2rem' }}></i>
                </button>
                <button className="btn btn-sm btn-primary" title="Search" type="submit">
                  <Search />
                </button>
              </Card.Footer>
            </Form>
          </Card>
        )
        }

        <Container >
          <Row className="mx-2">

            <Table hover size="xl" bordered className="border shadow-sm" >
              <thead className="table-secondary">
                <tr>
                  {headerWithoutAuditFields()
                    .map((item, index) => {
                      // console.log(item)
                      return (
                        <th key={index} className={`fw-bold clickable`}
                          onClick={() => {
                            // console.log(item, sortBy, sortByOrder)
                            setSortBy(item.FieldName);
                            if (item.FieldName === sortBy) {
                              if (sortByOrder === 'ASC') {
                                setSortByOrder('DESC')
                              } else {
                                setSortByOrder('ASC')
                              }
                            } else {
                              setSortByOrder('ASC')
                            }
                          }}
                        >{item.FieldTitle} {sortBy === item.FieldName && sortByOrder === 'ASC' ? <i className="fas fa-sort-up"></i> : sortBy === item.FieldName && sortByOrder === 'DESC' ? <i className="fas fa-sort-down"></i> : null} </th>
                      )
                    })}
                </tr>
              </thead>
              <tbody>
                {model?.data && model.data.Rows.map((item, index) => {

                  // console.log("item", item, "header", tableHeader);
                  return (
                    <tr key={index} onClick={() => { setSelectedItem(item) }} className={selectedItem && selectedItem.GUID === item.GUID ? "active" : ""}>
                      {headerWithoutAuditFields()
                        .map((column, index2) => {
                          if (column.FieldType === "datetime" || column.FieldType === "date") {
                            return (
                              <td key={index2} className={` `}>{moment(item[column.FieldName]).format("MM/DD/YYYY")}</td>
                            )
                          }

                          return (
                            <td key={index2} className={` `}>{
                              (typeof item[column.FieldName] === 'boolean') ? <input type="checkbox" checked={item[column.FieldName]} className="" readOnly /> : item[column.FieldName]
                            }</td>
                          )
                        })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>

            <div className="d-flex justify-content-between align-items-center">
              {(model?.data?.Columns && model?.data?.Rows.length > 0) && (<div className="d-flex align-items-center gap-2">
                <p className="m-0">Records per page</p>
                <div>
                  <select
                    className="form-control form-control-sm"
                    value={allowedPageSizes.find(item => item.value === RecordsQuantity)}
                    onChange={(e) => {
                      console.log("selected value", e.target.value)
                      if (e.target.value < 1) {
                        setRecordsQuantity(recordCount);
                      } else {
                        setRecordsQuantity(e.target.value)
                      }
                      setCurrentPage(1)
                    }}
                  >
                    {allowedPageSizes.map((item, index) => {
                      // console.log(item);
                      return (
                        <option key={index} value={item.value}>
                          {item.text}
                        </option>
                      );
                    }
                    )}
                  </select>
                </div>
              </div>)}
              {(model?.data?.Columns && model?.data?.Rows.length > 0) && (<Pagination className="my-0">
                <Pagination.First disabled={loading} onClick={() => setCurrentPage(1)} />
                <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage <= 1 || loading} />
                <Pagination.Item disabled={loading} active>{currentPage}</Pagination.Item>
                {(currentPage + 1 >= totalPages) ? null : <Pagination.Item disabled={loading} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</Pagination.Item>}
                {(currentPage + 2 >= totalPages) ? null : <Pagination.Item disabled={loading} onClick={() => setCurrentPage(currentPage + 2)}>{currentPage + 2}</Pagination.Item>}
                <Pagination.Next disabled={currentPage >= totalPages || loading} onClick={() => setCurrentPage(currentPage + 1)} />
                <Pagination.Last disabled={loading} onClick={() => setCurrentPage(totalPages)} />
              </Pagination>)}
            </div>
            {/* {console.log("Current Page", currentPage, "Total Pages", totalPages)} */}
          </Row>
        </Container>
      </>
    );
  };

  //EVENTS


  useEffect(() => {
    setOpen(false)
    setSelectedItem(null)
    setActionMode(null)
    setGeneralError(null)
    setModel(initialModel);
    checkIfPinned();
    setIsSearching(false);
    setSearch("");
    bindDataModelRemote();
    bindDataHeaderRemote();
    setCurrentPage(1);
    //bindDataRemote();

    return () => formik.resetForm();
  }, [params.id]);

  useEffect(() => {

    console.log("section updated", model)
    prepareFormikObject();
  }, [section, fields]);
  // END EVENTS
  useEffect(() => {
    console.log("entro record quantity")
    bindDataRemote();
  }, [RecordsQuantity])

  useEffect(() => {
    console.log("Record Count", recordCount, "Records Quantity", RecordsQuantity);
    console.log("Pages", Math.ceil(recordCount / RecordsQuantity))
    setTotalPages(Math.ceil(recordCount / RecordsQuantity))
  }, [recordCount, RecordsQuantity])
  if (!section) {
    return <></>
  }
  return (
    <React.Fragment>
      {open ?
        <Modal
          show={open}
          onHide={() => toggleOpen(null)}
          backdrop="static"
          keyboard={false}
          size={formSize}
          className={"modalRight " + formSize}
        >
          <Modal.Header closeButton>
            <Modal.Title>{section.FormTitle}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              <Row>
                {/* CONTENT */}
                {
                  fields.map((item, index) => {


                    return (
                      <Col
                        className={
                          item.cssClasss + (!item.Visible ? " d-none" : "")
                        }
                        key={index}
                      >
                        <label className={item.HtmlType === 'separator' ? `me-2 ${(index !== 0 ? "mt-4 pt-4" : "mt-2")}  separatorTitle` : "me-2 mt-3 "}>{item.FieldTitle}
                          {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
                        </label>
                        <br />

                        <FieldSelector
                          actionMode={actionMode}
                          model={item}
                          key={index}
                          obj={formik.values}
                          // limit longitudes
                          updateField={updateField}
                          value={formik.values[item.FieldName]}
                        ></FieldSelector>
                        {formik.errors[item.FieldName] ? (
                          <div className="invalid text-sm">
                            {formik.errors[item.FieldName]}
                          </div>
                        ) : null}
                      </Col>
                    );
                  })}
              </Row>
              {Object.values(formik.errors).length > 0 && Object.values(formik.errors).map(item => {

                return <Row className="invalid me-2 ">{item}</Row>
              })}
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex gx-3 align-items-center justify-content-end">
                {Object.values(formik.errors).length > 0 && (
                  <span className="invalid me-2">
                    Please check the forms for errors
                  </span>
                )}

                {generalError ? <span className="invalid me-2">
                  {generalError}
                </span> : null}
                <button className="btn  me-2" type="button" onClick={() => toggleOpen(null)}>Close</button>
                <button className="btn btn-primary " type="button" onClick={() => [formik.submitForm()]}>Save</button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal> : null}
      {section?.FormPath && <Breadcrumbs pathTitle={section?.FormPath} />}
      <Container>
        <div className="row mx-0" >
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">

              <React.Fragment>
                <div className="d-flex align-items-center my-1">
                  <i className={section.FormIcon + " me-3 fa-2x"}></i>
                  <div className="d-flex flex-column">
                    <h5 className={"my-1"}>{section.FormTitle}

                    </h5>
                  </div>
                </div>
              </React.Fragment>
              {loading ? (
                <React.Fragment>
                  <div className="">
                    <div className="w-25 mx-auto my-1" style={{ paddingTop: "" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              <div className="w-auto d-flex align-items-center">

                {actionMode === null ? (
                  <>
                    <button className="btn btn-sm btn-primary my-1 me-2" title="Create" disabled={loading} onClick={() => toggleOpen("I")}>
                      <i className="fa fa-plus"></i>
                    </button>
                    {!isSearching && (
                      <button className={`btn btn-sm  my-1 me-2`} title="Search" onClick={() => setIsSearching(true)}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    )}
                  </>
                ) : null}
                {selectedItem ? (
                  <>
                    <button className="btn btn-sm  my-1 me-2" title="Update" disabled={loading} onClick={() => toggleOpen("U")} aria>
                      <i className="fa fa-pencil"></i>
                    </button>
                    <button className="btn btn-sm  my-1 me-2" title="View" disabled={loading} onClick={() => toggleOpen("C")}>
                      <i className="fa fa-eye"></i>
                    </button>


                  </>
                ) : null}



                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {isPinned ? <Dropdown.Item onClick={() => { addFavorite() }}>Remover de favoritos</Dropdown.Item> : <Dropdown.Item onClick={() => { addFavorite() }}>Agregar a favoritos</Dropdown.Item>}
                    <Dropdown.Divider />
                    {selectedItem ?
                      <Dropdown.Item onClick={() => { toggleOpen('D') }}>Duplicar</Dropdown.Item> : null}


                    {actionMode === null ?
                      <Dropdown.Item onClick={() => { exportToExcel() }}>Export to Excel</Dropdown.Item> : null}
                    {actionMode === "C" ?
                      <Dropdown.Item
                        onClick={() => exportToPDF()}>Export to PDF</Dropdown.Item> : null}

                    <Dropdown.Divider />
                    {selectedItem ?
                      <Dropdown.Item onClick={() => deleteConfirm()}>Delete</Dropdown.Item> : null}
                  </Dropdown.Menu>
                </Dropdown>

              </div>
            </div>
            {loadGRIDUI()}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
