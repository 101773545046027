
// Format value as money


//n= number
//c= comma separator
//t = houndry separator
//
export const FormatMoney = val => {

	return new Intl.NumberFormat().format(val)
}