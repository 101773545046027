import React, { useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import FieldSelector from "../customComponents/FieldSelector";
import { useFormik } from "formik";
import * as yup from "yup";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { FilterFieldset } from "./fields/FilterFields";
import ReservationSecFilter from "./ReservationSecFilter";
import "./guest-reservation-custom-styles.css";

import { useTranslation } from "react-i18next";
import { parsedUser } from "../../utils/GetCurrentUser";

const ReservationFilter = (props) => {
  const user = parsedUser();
  const { t } = useTranslation();

  const fields = FilterFieldset;
  const [validationsSchema, setValidationSchema] = useState(null);
  const [secondaryFilter, setSecondaryFilter] = useState({
    all: true,
    inhouse: true,
    reserved: true,
    checkedOut: true,
    noShow: true,
    canceled: true,
    bumpedOut: true,
  });

  useEffect(() => {
    prepareFormikObject();
  }, []);

  const handleSubmit = async (formikObj) => {
    const obj = { ...formikObj, ...secondaryFilter };
    let objSPVersion = "";
    fields.forEach((item) => {
      if (item.ReadOnly || item.HtmlType === "separator") return;

      // if (item.HtmlType === 'select') {
      //   debugger;
      // }
      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined ||
          obj[item.FieldName] === null ||
          obj[item.FieldName] === ""
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : obj[item.FieldName] ?? "null";
      } else if (typeof item === "boolean") {
        objSPVersion += obj[item.FieldName] === true ? 1 : 0;
      } else if (item.HtmlType === "radio") {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined ||
          obj[item.FieldName] === null ||
          obj[item.FieldName].length === 0 ||
          obj[item.FieldName] === "Invalid date"
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
    });

    objSPVersion = objSPVersion+ `, @IdBusinessUnit=${user.IdBusinessUnit}`;
    // props.filterBy(objSPVersion);
    console.log("🚀 ~ handleSubmit ~ objSPVersion:", objSPVersion)
    // @IdBussinessUnit=${user.IdBusinessUnit}
    // objSPVersion = objSPVersion
    props.handleFilter(objSPVersion);
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const prepareFormikObject = () => {
    //("Preparing formik object", fields)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const updateField = (fieldName, fieldValue) => {
    if (fieldName === "Room") {
      fieldValue = fieldValue.replace(/[^a-zA-Z0-9-]/g, "");
    }
    formik.setFieldValue(fieldName, fieldValue);
    //	setRecord({ ...record, fieldName: fieldValue });
  };

  return (
    <Accordion className="mb-4 mt-0 p-0" defaultActiveKey={"0"}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="my-0">
          <span className="h5 m-0" style={{ fontSize: "14px" }}>
            {t("Filter Reservations")}
          </span>
        </Accordion.Header>
        <Accordion.Body className="bg-light p-0">
          <Card className="w-100">
            {/* <Card.Header className="w-full d-flex justify-content-between align-items-center">
        <Card.Title>Filter Reservations</Card.Title>
      </Card.Header> */}
            <Form onSubmit={formik.handleSubmit}>
              <Card.Body className="bg-light">
                <Row className="mx-0">
                  <Col xs={12} md={8}>
                    <Row>
                      {fields
                        .filter((x) => x.Group === 0)
                        .map((item, index) => (
                          <Row
                            className={
                              item.cssClasss +
                              (!item.Visible ? " d-none" : " mb-2")
                            }
                            key={index}
                          >
                            <Col className="d-flex">
                              {item.FieldName === "ArrivalFrom" ? (
                                <>
                                  <Col
                                    xs={6}
                                    className={`me-2 ${
                                      item.FieldName === "ArrivalTo" && "ms-5"
                                    }`}
                                  >
                                    <label
                                      className={`mt-2 me-1 ${
                                        item.FieldName === "ArrivalTo" &&
                                        "ms-4 text-center"
                                      }`}
                                      style={{ fontSize: "12px" }}
                                    >
                                      {t(item.FieldTitle)}
                                    </label>
                                  </Col>
                                  {/* {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null} */}
                                  <Col xs={9}>
                                    <FieldSelector
                                      style={{ width: "100%" }}
                                      model={item}
                                      key={index}
                                      obj={formik.values}
                                      updateField={updateField}
                                      value={formik.values[item.FieldName]}
                                    />
                                    {formik.errors[item.FieldName] && (
                                      <div className="invalid text-sm">
                                        {formik.errors[item.FieldName]}
                                      </div>
                                    )}
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col
                                    xs={3}
                                    className={
                                      item.FieldName === "ArrivalTo" &&
                                      "text-center ms-4 "
                                    }
                                  >
                                    <label
                                      className="mt-2 me-2"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {t(item.FieldTitle)}
                                    </label>
                                    {/* {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null} */}
                                  </Col>
                                  <Col xs={9}>
                                    <FieldSelector
                                      style={{ width: "100%" }}
                                      model={item}
                                      key={index}
                                      obj={formik.values}
                                      updateField={updateField}
                                      value={formik.values[item.FieldName]}
                                    />
                                    {formik.errors[item.FieldName] && (
                                      <div className="invalid text-sm">
                                        {t(formik.errors[item.FieldName])}
                                      </div>
                                    )}
                                  </Col>
                                </>
                              )}
                            </Col>
                          </Row>
                        ))}
                    </Row>
                  </Col>
                  <Col xs={12} md={2}>
                    <Row className="mx-0">
                      {fields
                        .filter((x) => x.Group === 1)
                        .map((item, index) => (
                          <Col
                            className={
                              item.cssClasss +
                              (!item.Visible ? " d-none" : " mb-2")
                            }
                            key={index}
                          >
                            <label
                              className="me-2 mt-2"
                              style={{ fontSize: "12px" }}
                            >
                              {t(item.FieldTitle)}
                            </label>
                            {item.Required ? (
                              <i className="required-asterisk ms-1 text-danger">
                                *
                              </i>
                            ) : null}

                            <FieldSelector
                              // actionMode={props.actionMode}
                              model={item}
                              key={index}
                              obj={formik.values}
                              updateField={updateField}
                              value={formik.values[item.FieldName]}
                            />

                            {formik.errors[item.FieldName] && (
                              <div className="invalid text-sm">
                                {t(formik.errors[item.FieldName])}
                              </div>
                            )}
                          </Col>
                        ))}
                    </Row>
                  </Col>
                  <Col xs={12} md={2}>
                    <ReservationSecFilter
                      filterBy={(data) => setSecondaryFilter(data)}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <div className="display-6 text-success">
                      {t("Reservations found:")} {props.count || 0}
                    </div>
                  </Col>
                  <Col xs="auto text-end">
                    <button
                      className="btn btn-sm btn-secondary me-2"
                      type="button"
                      title={t("Clear")}
                      onClick={() => formik.resetForm()}
                    >
                      <i className="fas fa-eraser"></i>
                    </button>
                    <button
                      className="btn btn-sm btn-primary"
                      title={t("Search")}
                      type="submit"
                      disabled={props.isLoading}
                    >
                      <Search />
                    </button>
                  </Col>
                </Row>
              </Card.Footer>
            </Form>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ReservationFilter;
