import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";

import RatePeriodPolicies from "./RatePeriodPolicies";

import "semantic-ui-css/semantic.min.css";

import { Step } from "semantic-ui-react";
import RatePeriodGeneral from "./RatePeriodGeneral";
import RatePeriodRoomTypes from "./RatePeriodRoomTypes";

import { useTranslation } from "react-i18next";
import RatePeriodsSpecialBookingArrival from "../ContractForm/SpecialRate/RatePeriodsSpecialBookingArrival";

const RatePeriodDetails = (props) => {
  const { t } = useTranslation();

  const [record, setRecord] = useState(props.record ?? {});
  const [recordGUID, setRecordGUID] = useState(null);
  const [isSpecial, setIsSpecial] = useState("C");

  // LOAD THE ROOM TYPE OCUPATION SELECTED
  useEffect(() => {
    if (props.record) {
      // console.log(props.record);
      setRecord(props.record);
      setRecordGUID(props.record?.GUID);
    }

    return () => props.clearSelection();
  }, []);

  // useEffect(() => {
  //   if (props) {
  //     console.log("🚀 ~ props.records looking for Arrival:", props)
  //   }
  // }, [props])

  const generalRef = useRef(null);
  const [step, setStep] = useState(0);
  const setActiveStep = (obj) => {
    if (step === 0 && props.actionMode === "I") {
      setStep(obj);
      return;
    }
    generalRef.current?.submit();
    props.refreshRatePeriodsTable();
    setStep(obj);
  };
  const [requestToSaveGeneral, setRequestToSaveGeneral] = useState(false);

  // useEffect(() => {

  // 	// console.log("room type selected", roomTypeSelected)
  // 	if (roomTypeSelected === null) {
  // 		return;
  // 	}
  // 	let types = roomTypes;
  // 	types.filter(x => x.GUID === roomTypeSelected.GUID)[0] = roomTypeSelected;
  // 	setRoomTypes(types);
  // }, [roomTypeSelected])

  return (
    <Modal
      show={props.show}
      onHide={() => props.toggle(props.businessUnitSelected, null, true)}
      backdrop="static"
      keyboard={false}
      size="xl"
      className="modalRight"
      // dialogClassName='modal-90w'
    >
      <Modal.Header closeButton>
        <Row>
          <Col>
            <h4>
              {props.businessUnitSelected && props.businessUnitSelected.Name} -
              {t("Rate Period")}
            </h4>
          </Col>
        </Row>
      </Modal.Header>

      <Modal.Body className="p-0">
        <Container fluid>
          <Row>
            <Col xs={12} className="my-4">
              <Step.Group widths={5}>
                <Step active={step === 0} onClick={() => setActiveStep(0)}>
                  {/* <Icon name='truck' /> */}
                  <Step.Content>
                    <Step.Title>{t("Rate Period General")}</Step.Title>
                  </Step.Content>
                </Step>
                <Step
                  active={step === 1}
                  disabled={step === 0 && props.actionMode === "I"}
                  onClick={() => setActiveStep(1)}
                >
                  {/* <Icon name='truck' /> */}
                  <Step.Content>
                    <Step.Title>{t("Rate Details")}</Step.Title>
                  </Step.Content>
                </Step>

                <Step
                  active={step === 2}
                  disabled={step === 0 && props.actionMode === "I"}
                  onClick={() => setActiveStep(2)}
                >
                  {/* <Icon name='info' /> */}
                  <Step.Content>
                    <Step.Title>{t("Exception Policies")}</Step.Title>
                  </Step.Content>
                </Step>
                {isSpecial === "S" && <Step
                  active={step === 3}
                  disabled={step === 0 && props.actionMode === "I"}
                  onClick={() => setActiveStep(3)}
                >
                  {/* <Icon name='info' /> */}
                  <Step.Content>
                    <Step.Title>{t("Booking & Arrival")}</Step.Title>
                  </Step.Content>
                </Step>}
              </Step.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {
                // console.log(props)
              }
              {/* GENERIC */}
              {step === 0 ? (
                <RatePeriodGeneral
                  ref={generalRef}
                  setActiveStep={setActiveStep}
                  setIsSpecial={setIsSpecial}
                  step={step}
                  actionMode={props.actionMode}
                  setActionMode={props.setActionMode}
                  businessUnitSelected={props.businessUnitSelected}
                  record={record} //This is for the current record
                  requestToSave={requestToSaveGeneral}
                  setRecordGUID={setRecordGUID}
                  recordGUID={recordGUID}
                  setRequestToSaveGeneral={setRequestToSaveGeneral}
                  Contract={props.Contract}
                  allRates={props.allRates}
                  setRecord={setRecord}
                />
              ) : null}
              {step === 1 ? (
                <RatePeriodRoomTypes
                  recordGUID={recordGUID}
                  setActiveStep={setActiveStep}
                  step={step}
                  actionMode={props.actionMode}
                  businessUnitSelected={props.businessUnitSelected}
                ></RatePeriodRoomTypes>
              ) : null}

              {step === 2 ? (
                <RatePeriodPolicies
                  page={{
                    PageIndex: 2,
                    PageTitle: t("Rate Policies"),
                    PageIcon: "fa-solid fa-file-lines",
                    MultiRecord: true,
                    Active: true,
                  }}
                  ratePeriodGUID={recordGUID}
                ></RatePeriodPolicies>
              ) : null}
              {(isSpecial === "S" && step === 3) && (
              <RatePeriodsSpecialBookingArrival
                  contractGUID={props.ParentGUID}
                  Contract={props.Contract}
                  SpecialRate={record}
                  selectedItem={record}
                  record={record}
                />
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gx-3 align-items-center justify-content-end">
          <button
            className="btn me-2"
            type={"button"}
            onClick={() => props.toggle(props.businessUnitSelected, null, true)}
          >
            {t("Close")}
          </button>
          {step > 0 && (
            <button
              className="btn btn-secondary me-2"
              type={"button"}
              onClick={() => setStep(step - 1)}
            >
              {t("Prev")}
            </button>
          )}
          {(step < 2 || (isSpecial === 'S' && step === 2)) && (
            <button
              className="btn btn-primary me-2"
              type={"button"}
              onClick={() => {
                // console.log(step)
                if (step === 0 && props.actionMode === "U") {
                  generalRef.current?.submit();
                  setStep(step + 1);
                }
                if (step === 0) {
                  setRequestToSaveGeneral(!requestToSaveGeneral);
                  props.refreshRatePeriodsTable();
                  //Means we need to save the current record first and then move to next one
                } else {
                  setStep(step + 1);
                }
              }}
            >
              {t("Next")}
            </button>
          )}
          {((step === 2 && isSpecial === "C") || (step === 3  && isSpecial === "S")) ? (
            <button
              className="btn btn-primary me-2"
              type={"button"}
              onClick={() => {
                props.toggle(props.businessUnitSelected, null, true);
              }}
            >
              {t("Finish")}
            </button>
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RatePeriodDetails;
