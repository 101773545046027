import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";

import ReservationInfo from "../ReservationInfo";
import { useFormik } from "formik";
import API from "../../../api/api";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import { useEffect, useState } from "react";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { CommentField } from "../fields/CommentField";
import { NumberReservationsFields } from "../fields/NumberReservationsFields";
import { HeadReservationCardFields } from "../fields/HeadReservationCardFields";
import { RestaurantReservationInfoFields } from "../fields/RestaurantReservationInfoFields";
import * as yup from "yup";
import SelectedRooms from "../SelectedRooms";
import ListRooms from "./ListRooms";
import ListGuest from "./ListGuest";
import { GenericFields } from "../../group-reservation/GenericFields";
import NumberReservations from "./NumberReservations";
import RestaurantHoursAvailability from "./RestaurantHoursAvailability";
import { useToast } from "../../../contexts/toast";
import AvailabilityPreview from "../AvailabilityPreview";
import PrintRestaurantReservation from "../PrintRestaurantReservation";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { dateFormater } from "../../../utils/date-formater";
import { t } from "i18next";

const ReservationDetailsContainer = (props) => {
  const user = parsedUser();
  const toast = useToast();
  const [errors, setErrors] = useState([]);
  let [actionMode, setActionMode] = useState();
  const [validationSchema, setValidationSchema] = useState(null);
  const fields = [
    ...RestaurantReservationInfoFields,
    ...HeadReservationCardFields,
    ...NumberReservationsFields,
    ...CommentField,
  ];
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [restaurantAvailability, setRestaurantAvailability] = useState([]);
  const [numbersReservationsFields] = useState(NumberReservationsFields);
  const [headReservationCardFields, setHeadReservationCardFields] = useState(
    HeadReservationCardFields
  );
  const [commentField] = useState(CommentField);
  const [showRooms, setShowRooms] = useState(false);

  const [paxes, setPaxes] = useState([]);

  const [rooms, setRooms] = useState([
    /* {
      bussinesUnit: "Cofresi",
      room: 101,
    },
    {
      bussinesUnit: "Tropical",
      room: 217,
    }, */
  ]);

  const saveInitialReservation = async () => {
    // console.log('Saving initial reservation')
    // console.log(guests, guests.length, selectedReservation)
    // setGuests([]);
    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=I`;
    let queryData = {
      Data: "",
    };
    let request = await API.postAction(
      `api/RestaurantReservation/AddEdit?${queryString}`,
      null
    );
    if (request.status === 200) {
      let response = JSON.parse(request.data[0].JSONData);
      if (response.Error) {
        alert(response.Msg);
        return;
      }
      setSelectedReservation(response[0].JSONData);
      setSelectedReservation((prev) => {
        return { ...prev, ReservationDate: dateFormater(new Date()) };
      });
      // console.log("🚀 ~ saveInitialReservation ~ response[0].JSONData:", response[0].JSONData)

      // console.log("response,", response[0].JSONData)
      setActionMode("U");
    }
    // console.log(request)
  };

  const validateNecesaryDataSelected = () => {
    if (
      totalGuests &&
      totalGuests.length > 0 &&
      restaurantAvailability &&
      restaurantAvailability.length > 0
    ) {
      console.log(restaurantAvailability.some((item) => item.Apply));
      return restaurantAvailability.some((item) => item.Apply);
    } else return true;
  };

  const handleSubmit = async (obj) => {
    console.log(obj, selectedReservation);
    console.log(obj);

    // if (props.actionMode === "I") {
    //   actionMode = "U";
    // }
    let objSPVersion = "";

    Object.keys(selectedReservation).forEach((item) => {
      console.log(
        "field",
        item,
        selectedReservation["Id" + item],
        selectedReservation[item],
        typeof selectedReservation[item]
      );
      if (
        item === "RowStatus" ||
        item === "IdUserAdd" ||
        item === "DateAdd" ||
        item === "IdUserMod" ||
        item === "DateMod" ||
        item === "Id" ||
        (item && selectedReservation["Id" + item]) ||
        item === "Customer"
      ) {
        return;
      }
      objSPVersion += (objSPVersion.length > 0 ? ", " : "") + `@${item}=`;
      if (
        selectedReservation[item] === null ||
        selectedReservation[item] === undefined
      ) {
        objSPVersion += "null";
      } else if (typeof selectedReservation[item] === "number") {
        objSPVersion +=
          selectedReservation[item] === undefined ||
          selectedReservation[item] === null
            ? null
            : selectedReservation[item];
      } else if (typeof [selectedReservation[item] === "string"]) {
        objSPVersion += "'" + selectedReservation[item] + "'";
      } else {
        objSPVersion += selectedReservation[item];
        //   "null";
      }
    });

    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}`;

    let queryData = {
      Data: objSPVersion,
    };

    let uri = "api/RestaurantReservation/AddEdit?";

    let request = await API.postAction(uri + queryString, queryData);

    console.log(request);

    if (request.status === 200) {
      let response = JSON.parse(request.data[0].JSONData)[0];
      console.log(response);
      if (response.Error) {
        toast({ type: "error", message: response.Msg, height: "100px" });
        //	setGeneralError(response.Msg);
        return;
      }
      // console.log(response.JSONData);
      //	props.setSelectedITem(response.JSONData);
    }
    toast({
      type: "success",
      message: "Record saved successfully",
    });

    confirmAlert({
      closeOnClickOutside: false,
      message: "Do you want to print the reservation?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            togglePrint();
          },
        },
        {
          label: "No",
          onClick: () => {
            props.toggle();
          },
        },
      ],
    });
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    fields.forEach((item) => {
      if (item.FieldName === "Id" || item.FieldName === "File") return;

      initialObject[item.FieldName] = item.value;

      if (item.Required) {
        initialObjectValidation[item.FieldName] = yupTypeValidator(item);
      }
    });

    formik.initialValues = initialObject;
    setValidationSchema(yup.object(initialObjectValidation));
  };

  const formik = useFormik({
    initialValues: selectedReservation ? selectedReservation : {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const UpdateField = (fieldName, fieldValue) => {
    setSelectedReservation({ ...selectedReservation, [fieldName]: fieldValue });
    formik.setFieldValue(fieldName, fieldValue);
  };

  const toggleRooms = () => {
    setShowRooms(!showRooms);
    if (showRooms) {
      reloadTables();
    }
  };

  const [availabilityLoading, setAvailabilityLoading] = useState(false);

  const requestAvailability = async () => {
    try {
      setAvailabilityLoading(true);
      let query = `IdUser=${user.IdUser}&token=${user.Token}&IdRestaurant=${selectedReservation.IdRestaurant}&ReservationDate=${selectedReservation.ReservationDate}`;
      let request = await API.getAction(
        "api/RestaurantReservation/RestaurantAvailability",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      console.log(recordResult, results, parsedResults);
      setRestaurantAvailability(recordResult);

      console.log(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
      setAvailabilityLoading(false);
    }
  };

  const requestRooms = async () => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDRestaurantsReservation=${selectedReservation.GUID}`;
      let request = await API.getAction(
        "api/RestaurantReservation/Rooms",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      console.log(recordResult, results, parsedResults);

      setRooms(recordResult);
      if (recordResult === null) {
        throw new Error("No data found");
      }
      console.log(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const [totalGuests, setTotalGuests] = useState([]);

  const requestTotalGuests = async () => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDRestaurantsReservation=${selectedReservation.GUID}`;
      let request = await API.getAction(
        "api/RestaurantReservation/totalGuest",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      console.log(recordResult);
      setTotalGuests(recordResult);
      if (recordResult === null) {
        throw new Error("No data found");
      }
      console.log(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const requestPax = async () => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDRestaurantsReservation=${selectedReservation.GUID}`;
      let request = await API.getAction("api/RestaurantReservation/Pax", query);

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      console.log(recordResult, results, parsedResults);
      setPaxes(recordResult);
      if (recordResult === null) {
        throw new Error("No data found");
      }
      console.log(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const reloadTables = async () => {
    if (selectedReservation && selectedReservation.GUID) {
      requestRooms();
      requestPax();
      requestTotalGuests();
    }
  };

  const bindDataDetailsRemote = async (reservation) => {
    try {
      let query = `IdUser=${user?.IdUser}&token=${user?.Token}&RecordId=${reservation?.GUID}`;
      let request = await API.getAction(
        "api/RestaurantReservation/Details",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      if (recordResult === null) {
        throw new Error("No data found");
      }
      // console.log("🚀 ~ bindDataDetailsRemote ~ recordResult:", recordResult)

      setSelectedReservation(recordResult);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const [showAvailability, setShowAvailability] = useState(false);
  const toggleAvailability = () => {
    setShowAvailability(!showAvailability);
  };
  // EFFECTS
  useEffect(() => {
    if (props.selectedReservation && props.actionMode === "U") {
      setSelectedReservation(props.selectedReservation);
      bindDataDetailsRemote(props.selectedReservation);
    }
  }, [props.selectedReservation]);

  useEffect(() => {
    if (props.selectedReservation) {
      console.log("🚀 ~ useEffect ~ props:", props);
      console.log(
        "🚀 ~ ReservationDetailsContainer ~ props.selectedReservation:",
        props.selectedReservation
      );
    }
  }, [props.selectedReservation]);

  useEffect(() => {
    if (fields.length > 0) {
      prepareFormikObject();
    }
  }, []);

  useEffect(() => {
    let RestaurantFields = headReservationCardFields;
    RestaurantFields = RestaurantFields.map((item) => {
      console.log(paxes, rooms);
      if (item.FieldName === "ReservationDate" && props.actionMode === "I") {
        item.min = moment().format("YYYY-MM-DD");
      } else {
      }
      return item;
    });
    setHeadReservationCardFields(RestaurantFields);
  }, [paxes]);

  useEffect(() => {
    if (
      selectedReservation &&
      selectedReservation.IdRestaurant &&
      selectedReservation.ReservationDate &&
      selectedReservation.ReservationDate !== "Invalid date"
    ) {
      console.log("new value", selectedReservation);
      requestAvailability();
    }
    reloadTables();
  }, [selectedReservation]);

  useEffect(() => {
    if (props.actionMode && props.actionMode === "I") {
      saveInitialReservation();
    }
  }, [props.actionMode]);

  const [showPrint, setShowPrint] = useState(false);
  const togglePrint = () => {
    setShowPrint(!showPrint);
  };

  return (
    <>
      {showPrint && (
        <PrintRestaurantReservation
          show={showPrint}
          handleClose={setShowPrint}
          GUIDReservation={selectedReservation.GUID}
        />
      )}
      {showAvailability && (
        <AvailabilityPreview
          show={showAvailability}
          toggle={toggleAvailability}
          selectedReservation={selectedReservation}
        />
      )}
      {showRooms && (
        <SelectedRooms
          rooms={rooms}
          show={showRooms}
          toggle={toggleRooms}
          updateFields={UpdateField}
          selectedReservation={selectedReservation}
          GUIDReservation={selectedReservation.GUID}
        />
      )}
      <Modal
        show={props.show}
        onHide={() => props.toggle()}
        backdrop="static"
        keyboard={false}
        className="modalRight"
        dialogClassName="modal-90w"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <Row>
              <Col>
                <h4>
                  Reservation -{" "}
                  {props.actionMode === "I"
                    ? "New"
                    : props.actionMode === "U"
                    ? "Edit"
                    : ""}
                </h4>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <ReservationInfo
                  selectedReservation={selectedReservation}
                  errors={errors}
                  values={selectedReservation}
                />
              </Col>
              {/* <Col xs={12} lg={6}>
				<GuestInformation />
			  </Col> */}
              <Col xs={12} className="mt-3">
                <Row className="mt-2">
                  <Col xs={12} md={6}>
                    <Card style={{ minHeight: "300px" }}>
                      <Card.Header>
                        <Card.Title>{"Rooms"}</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <ListRooms
                          rooms={rooms}
                          toggle={toggleRooms}
                          reloadTables={reloadTables}
                          selectedReservation={selectedReservation}
                        />
                      </Card.Body>
                    </Card>
                    {/* <ListGuest /> */}
                  </Col>
                  <Col xs={12} md={6}>
                    <Card style={{ minHeight: "300px" }}>
                      <Card.Header>
                        <Card.Title>{"Pax"}</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <ListGuest
                          guest={paxes}
                          selectedReservation={selectedReservation}
                          reloadTables={reloadTables}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={12} className="mt-3">
                    <Card>
                      <Card.Header>
                        <Card.Title>{"Reservation"}</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Container>
                          <Row>
                            <Col xs={12} lg={5} className="mt-3">
                              <NumberReservations
                                selectedReservation={selectedReservation}
                                totalGuests={totalGuests}
                              />
                              <GenericFields
                                fields={commentField}
                                values={formik.values}
                                touched={formik.touched}
                                errors={formik.errors}
                                updateFields={UpdateField}
                              />
                            </Col>
                            <Col xs={12} lg={7} className="mt-3">
                              <Row>
                                <GenericFields
                                  fields={headReservationCardFields}
                                  values={formik.values}
                                  touched={formik.touched}
                                  errors={formik.errors}
                                  updateFields={UpdateField}
                                  section="RestaurantReservation"
                                />
                              </Row>
                              <RestaurantHoursAvailability
                                updateFields={UpdateField}
                                paxes={paxes}
                                selectedReservation={selectedReservation}
                                restaurantAvailability={restaurantAvailability}
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row className="w-100">
              <Col xs={6}>
                <div className="btn-group">
                  <button
                    // size="lg"
                    className={"btn btn-outline-secondary"}
                    type="button"
                    onClick={() => {
                      toggleAvailability();
                    }}
                  >
                    <i className={"fa fa-eye"} /> {"Availability Preview"}
                  </button>

                  {props.actionMode !== "I" && (
                    <button
                      className="btn btn-sm"
                      onClick={() => togglePrint()}
                      type="button"
                      style={{ backgroundColor: "#32496D", color: "white" }}
                    >
                      <i className="fa-solid fa-print"></i> Re-print
                    </button>
                  )}
                </div>
              </Col>
              <Col xs={6} className="d-flex justify-content-end">
                <button
                  className="btn btn-primary "
                  disabled={validateNecesaryDataSelected()}
                  type="submit"
                >
                  <i className="fa-regular fa-floppy-disk me-2"></i>
                  {t("Save")}
                </button>
              </Col>
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ReservationDetailsContainer;
