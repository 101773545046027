import { t } from "i18next";

export const CashierFilterFields = [
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "Room",
    FieldType: "varchar",
    FieldTitle: t("Room"),
    Position: 1,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "ReservationNumber",
    FieldType: "int",
    FieldTitle: t("Reservation #"),
    Position: 1,
    cssClasss: "col-12 col-md-4",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
];

export const PersonalInfoFields = [
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "ReservationNumber",
    FieldType: "varchar",
    FieldTitle: t("Reservation No."),
    Position: 1,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "Room",
    FieldType: "varchar",
    FieldTitle: t("Room"),
    Position: 1,
    cssClasss: "col-12 col-md-2",
    HtmlType: "badge",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "ReservationStatus",
    FieldType: "varchar",
    FieldTitle: t("Status"),
    Position: 1,
    cssClasss: "col-12 col-md-2",
    HtmlType: "badge",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "ArrivalDate",
    FieldType: "varchar",
    FieldTitle: t("Arrival"),
    Position: 1,
    cssClasss: "col-12 col-md-2",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "Nights",
    FieldType: "varchar",
    FieldTitle: t("Nights"),
    Position: 1,
    cssClasss: "col-12 col-md-1",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "DepartureDate",
    FieldType: "varchar",
    FieldTitle: t("Departure"),
    Position: 1,
    cssClasss: "col-12 col-md-2",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "RoomType",
    FieldType: "varchar",
    FieldTitle: t("Room Type"),
    Position: 1,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 0,
  },

  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "DailyRate",
    FieldType: "varchar",
    FieldTitle: t("Daily Rate"),
    Position: 1,
    cssClasss: "col-12 col-md-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "RateCategoryType",
    FieldType: "varchar",
    FieldTitle: t("Rate Type"),
    Position: 1,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "AdultQuantity",
    FieldType: "varchar",
    FieldTitle: t("Adults"),
    Position: 1,
    cssClasss: "col-12 col-md-2 text-nowrap",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "Child1Quantity",
    FieldType: "varchar",
    FieldTitle: t("Child 1"),
    Position: 1,
    cssClasss: "col-12 col-md-2 text-nowrap",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "Child2Quantity",
    FieldType: "varchar",
    FieldTitle: t("Child 2"),
    Position: 1,
    cssClasss: "col-12 col-md-2 text-nowrap",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 1,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "Child3Quantity",
    FieldType: "varchar",
    FieldTitle: t("Child 3"),
    Position: 1,
    cssClasss: "col-12 col-md-2 text-nowrap",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 1,
  },

  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "Customer",
    FieldType: "varchar",
    FieldTitle: t("Customer"),
    Position: 1,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "GuestName",
    FieldType: "varchar",
    FieldTitle: t("Name"),
    Position: 1,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "ReserveGuarantee",
    FieldType: "varchar",
    FieldTitle: t("Reserve Guarantee"),
    Position: 1,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 0,
  },
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "CreditLimit",
    FieldType: "varchar",
    FieldTitle: t("Credit Limit"),
    Position: 1,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
    Group: 1,
  },
];

export const CashierCommentsFields = [
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "CommentType",
    FieldType: "bigint",
    FieldTitle: t("Comment Type"),
    Position: 1,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"742569e0cbb969dacce83b236db6e01493e1474d1f613368346782d07ef0e7c4"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
];

export const CurrencyFields = [
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "IdCurrency",
    FieldType: "varchar",
    FieldTitle: t("Currency"),
    Position: 1,
    cssClasss: "col-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"637f44b9bafa1fe74b0a72c887b1c3262c833080fea07d40768858638f0fc87b"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
    Group: 'footer',
  },
];

export const CollectionFormatFields = [
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "CollectionFormat",
    FieldType: "varchar",
    FieldTitle: t("Collection Format"),
    Position: 1,
    cssClasss: "col-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "R",
    OptionValues: `[{"id": "R","text": "Reservation"},{"id": "M","text": "Master Folio"}]`,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    Group: 'footer',
  },
];

export const MasterFolioFields = [
  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "MasterFolio",
    FieldType: "varchar",
    FieldTitle: t("Master Folio"),
    Position: 1,
    cssClasss: "col-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
];