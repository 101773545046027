import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const ReservationsColorSet = ({ colorValues, vertical = true }) => {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t("Reservations")}</h4>
      <Row>
        {colorValues
          .map((value, index) => {
            return (
              <Col xs={vertical ? 12 : 4} className={`d-flex align-items-center`}>
                <div
                  key={index}
                  className="d-flex justify-content-evenly align-items-center mb-4 gap-4">
                  <input
                    className="form-control form-control-color"
                    type="color"
                    id={value.Code}
                    title={t("Background color")}
                    name="background"
                    value={value.BackgroundColor}
                    readOnly
                    disabled
                  />
                  <p className="fw-bold">{value.Description}</p>
                </div>
              </Col>
            );
          })}
      </Row>
    </>
  );
};
