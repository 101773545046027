import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";

import { useFormik } from "formik";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import * as yup from "yup";
import FieldSelector from "../../customComponents/FieldSelector";
import { parsedUser } from "../../../utils/GetCurrentUser";

import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";

import { useTranslation } from "react-i18next";

const BumpOutAddEdit = (props) => {
  const { t } = useTranslation();
  const [bumpOutInfo, setBumpOutInfo] = useState(null);
  const user = parsedUser();


  const toast = useToast();
  const [generalError, setGeneralError] = useState(null);

  const [validationsSchema, setValidationSchema] = useState(null);
  const fields = [


    {
      IdAppForm: 64,
      Id: 6400004,
      FieldName: "IdBumpOutHotels",
      FieldType: "bigint",
      FieldTitle: t("Bump Out Hotel"),
      Position: 2,
      cssClasss: "col-12 col-md-6 ",
      HtmlType: "select",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource:
        '{"HashID":"893bb53f91fb42d7b1f6beedaaba1dc7149b8bb29ffb1bdb80b89c5bea1fa881 "}',
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "BumpOutRoom",
      FieldType: "varchar",
      FieldTitle: t("Bump Out Room Reference"),
      Position: 2,
      cssClasss: "col-12 col-md-6",
      HtmlType: "text",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource:
        null,
      Required: false,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },

    {
      FieldName: "BumpOutRate",
      FieldType: "number",
      FieldTitle: t("Bump Out Rate Reference"),
      Position: 2,
      cssClasss: "col-12 col-md-6",
      HtmlType: "number",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource:
        null,
      Required: false,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "BumpOutDate",
      FieldType: "date",
      FieldTitle: t("Bump Out Date"),
      Position: 2,
      cssClasss: "col-12 col-md-6",
      HtmlType: "date",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource:
        null,
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "Returning",
      FieldType: "bit",
      FieldTitle: t("Returning?"),
      Position: 2,
      cssClasss: "col-12 col-md-6",
      HtmlType: "radio",
      PlaceHolder: "",


      DefaultValue: 0,
      OptionValues: `[{"id": 0,"text": "No"},{"id": 1,"text": 
        "Yes"
      }]`,
      DataSource:
        null,
      Required: false,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },

    {
      FieldName: "BumpOutReturningDate",
      FieldType: "date",
      FieldTitle: t("Returning Date"),
      Position: 2,
      cssClasss: "col-12 col-md-6",
      HtmlType: "date",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource:
        null,
      Required: false,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },

    {
      FieldName: "Comment",
      FieldType: "varchar",
      FieldTitle: t("Comments"),
      Position: 2,
      cssClasss: "col-12 ",
      HtmlType: "textarea",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource:
        null,
      Required: false,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
  ];
  const bindDataRemote = async (filter) => {
    // First start loading the model


    //First load records
    try {
      const query = `IdUser=${user.IdUser}&token=${user.Token}`;
      console.log(props.reservation)
      let GUIDReservation = props.reservation.GUID
      const queryData = {
        Data: `@GUIDReservation='${GUIDReservation}', @Accion ='T'`,
      };
      const request = await API.postAction(
        `api/reservations/reservationBumpOut?` + query,
        queryData ?? ""
      );
      const results = request.data[0];
      const parsedResults = JSON.parse(results.JSONData)[0];

      console.log("🚀 ~ bindDataRemote ~ parsedResults:", parsedResults);
      setBumpOutInfo(parsedResults.JSONData);
    } catch (error) {
      console.error(error);
    } finally {

    }
  };
  useEffect(() => {
    if (bumpOutInfo) {
      fillFormikObject(bumpOutInfo);
    }
  }, [bumpOutInfo]);
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const fillFormikObject = (record) => {
    if (fields && fields.length > 0) {
      fields.forEach((item) => {
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const handleSubmit = async (obj, event) => {
    console.log("object to save", obj);

    let reservation = props.reservation.ReservationGUID;
    if (!props.reservation.ReservationGUID) {
      reservation = props.reservation.GUID;
    }
    try {

      let objSPVersion = "";
      fields.forEach((item) => {
        if (item.HtmlType === "separator") return;

        if (item.HtmlType !== "separator") {
          objSPVersion +=
            (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
        }
        if (
          item.FieldType === "int" ||
          item.FieldType === "bool" ||
          item.FieldType === "bit" ||
          item.FieldType === "bigint"
        ) {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + obj[item.FieldName] + "'";
        } else if (item.HtmlType === "radio") {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + obj[item.FieldName] + "'";
        } else {
          console.log(item.FieldName, obj[item.FieldName]);
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null || obj[item.FieldName] === "Invalid date"
              ? "null"
              : "'" + obj[item.FieldName] + "'";
        }
      });
      // console.log("🚀 ~ handleSubmit ~ objSPVersion:", objSPVersion)
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;
      debugger;
      let queryData = {

        Data: objSPVersion + `,@Accion='U', @GUIDReservation='${reservation}'`,
      };

      let request = await API.postAction(
        `api/Reservations/ReservationBumpOut?${queryString}`,
        queryData
      );



      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: t("Record saved successfully"),
      });

      props.toggle();
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    prepareFormikObject();

  }, []);
  useEffect(() => {
    bindDataRemote();
  }, [props.selectedReservation]);
  // console.log(records)
  return (
    <Modal
      show={true}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
    // className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Bump out")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container fluid className="overflow-auto table-responsive">
          <Row>

            <Col xs={6}>
              <label className="me-2 mt-2">{t("Reservation Number")}</label>
              <br />
              <Form.Control
                type="text"
                disabled
                value={props.reservation?.ReservationNumber}

              />

            </Col>
            <Col xs={6}>
              <label className="me-2 mt-2">{t("Guest Name")}</label>
              <br />
              <Form.Control
                type="text"
                disabled
                value={props.reservation?.GuestName}

              />
            </Col>
            {fields && fields.length > 0 && fields.map((item, index) => {
              return (<Col
                className={item.cssClasss + (!item.Visible ? " d-none" : "")}
                key={index}
              >
                <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
                {item.Required ? (
                  <i className="required-asterisk ms-1 text-danger">*</i>
                ) : null}
                <br />

                <FieldSelector
                  actionMode={props.actionMode}
                  model={item}
                  key={index}
                  obj={formik.values}
                  updateField={updateField}
                  value={formik.values[item.FieldName]}
                ></FieldSelector>
                {formik.errors[item.FieldName] ? (
                  <div className="invalid text-sm">
                    {formik.errors[item.FieldName]}
                  </div>
                ) : null}
              </Col>)
            })}
          </Row>

        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gx-3 align-items-center justify-content-end">
          <button
            className="btn btn-success me-2"
            type="button"
            onClick={() => props.toggleAddEdit("K")}
          >
            {t("Check-in")}
          </button>
          {Object.values(formik.errors).length > 0 && (
            <span className="invalid me-2">
              {t("Please check the forms for errors")}
            </span>
          )}
          {/* <button className="btn me-2" type="button" onClick={props.toggle}>
            {t("Close")}
          </button> */}
          <button
            className="btn btn-primary me-2"
            type="button"
            onClick={() => [formik.submitForm()]}
          >
            {t("Save")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BumpOutAddEdit;
