import "devextreme/data/odata/store";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import Pagination from "../Pagination/Pagination";
import { RoomBlockTableFields } from "./Fields/RoomBlockFields";
import RoomBlockFilter from "./RoomBlockFilter";
import { CellHighlight, reservationColor } from "../../utils/custom-cell";
// import "./tableStyle.css";

export default function RoomBlockContainer({
  setSelectedItem,
  selectedItem,
  showAddEdit,
  setReloadData,
  reloadData,
}) {
  // PROPERTIES
  const user = parsedUser();
  const [recordCount, setRecordCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const initialModel = {
    section: {},
    data: {
      Columns: [],
      Rows: [],
    },
  };

  const [model, setModel] = useState(initialModel);
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortByOrder, setSortByOrder] = useState("DESC");
  const [tableHeader, setTableHeader] = useState(RoomBlockTableFields);
  const [objSPVersion, setObjSPVersion] = useState(""); //This object will be used to filter the data
  // END PROPERTIES

  //METHODS
  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS

  const bindDataRemote = async (filter) => {
    // First start loading the model
    setIsLoading(true);

    //First load records
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let quantity = RecordsQuantity === 0 ? recordCount : RecordsQuantity;
      query = query + `&Quantity=${quantity}&pageNumber=${currentPage}`;
      let prefix = "RoomBlock";
      let queryData = {
        Data: filter,
      };
      // let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.postAction(
        `api/${prefix}/Records?` + query,
        queryData ?? ""
      );
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData || [];
      let dataFromAPI = recordResult[0]
        ? recordResult[0].JSONConfig
          ? recordResult[0].JSONData
          : recordResult
        : [];

      let gridData = null;
      let gridColumns =
        dataFromAPI && dataFromAPI.length > 0
          ? Object.keys(dataFromAPI[0]).filter((x) => !x.startsWith("Id"))
          : null;

      setModel({
        ...model,

        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: gridData || dataFromAPI,
        },
      });

      await bindDataCountRemote(filter);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const bindDataCountRemote = async (filter) => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let queryData = {
        Data: filter,
      };
      let request = await API.postAction(
        `api/RoomBlock/Count?` + query,
        queryData ?? ""
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecordCount(parsedResults.JSONData.Count);
    } catch (error) {
      console.error(error);
    }
  };

  const headerWithoutAuditFields = () => {
    return (
      tableHeader &&
      tableHeader.filter(
        (x) =>
          x.FieldTitle !== "Audit Information" &&
          x.FieldName !== "Id" &&
          x.FieldName !== "IdUserAdd" &&
          x.FieldName !== "DateAdd" &&
          x.FieldName !== "IdUserMod" &&
          x.FieldName !== "DateMod"
      )
    );
  };

  const handleFilter = async (obj) => {
    setObjSPVersion(obj);
    setCurrentPage(1);
    await bindDataRemote(obj);
  };

  // END METHODS

  // Effects
  useEffect(() => {
    bindDataRemote();
    setSelectedItem(null);
  }, [sortBy, sortByOrder, RecordsQuantity, currentPage]);

  useEffect(() => {
    if (!showAddEdit) {
      bindDataRemote(objSPVersion);
    }
  }, [showAddEdit]);

  useEffect(() => {
    if (reloadData) {
      bindDataRemote(objSPVersion);
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    setTotalPages(Math.ceil(recordCount / RecordsQuantity));
  }, [recordCount, RecordsQuantity]);

  // END EFFECTS

  return (
    <>
      {/* BUTTON BAR */}
      {/* FILTER */}
      <RoomBlockFilter
        count={recordCount}
        handleFilter={(obj) => {
          handleFilter(obj)
          setObjSPVersion(obj)
        }}
        isLoading={isLoading}
      />
      {/* CONTAINER */}
      <Card>
        <Card.Body>
          <div className="w-100">
            <div className="wrapper" style={{ overflowY: "auto" }}>
              <table className="table table-bordered shadow-sm table-striped">
                <thead>
                  <tr>
                    {headerWithoutAuditFields().map((item, index) => {
                      return (
                        <th
                          key={index}
                          className={`fw-bold clickable`}
                          onClick={() => {
                            setSortBy(item.FieldName);
                            if (item.FieldName === sortBy) {
                              if (sortByOrder === "ASC") {
                                setSortByOrder("DESC");
                              } else {
                                setSortByOrder("ASC");
                              }
                            } else {
                              setSortByOrder("ASC");
                            }
                          }}
                        >
                          {item.FieldTitle}{" "}
                          {sortBy === item.FieldName &&
                            sortByOrder === "ASC" ? (
                            <i className="fas fa-sort-up"></i>
                          ) : sortBy === item.FieldName &&
                            sortByOrder === "DESC" ? (
                            <i className="fas fa-sort-down"></i>
                          ) : null}{" "}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                {model?.data ?
                    model?.data?.Rows.map((item, index) => {
                      return (
                        <tr key={index} className={`clickable ${item === selectedItem ? " active" : ""}`} onClick={() => setSelectedItem(item)}>
                          <td className="text-nowrap">{item?.Room}</td>
                          <td className="text-nowrap">{item?.StartDate}</td>
                          <td className="text-nowrap">{item?.EndDate}</td>
                          <td className=" text-nowrap">
                            <span
                              className="badge w-100"
                              style={CellHighlight(item.StateBgColor, item.StateTxtColor)}
                            >
                              {item?.StateDescription}
                            </span>
                          </td>
                          <td className="text-nowrap">{item?.RoomBlockType}</td>
                          <td className="text-nowrap">{item?.Department}</td>
                          <td className="text-nowrap">{item?.IdUserAdd}</td>
                          <td className="text-nowrap">{item?.Comment}</td>
                        </tr>
                      );
                    }) : <tr>
                      <td colSpan={headerWithoutAuditFields().length} className="text-center fw-bold">
                        No records found.
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              <Pagination
                isLoading={isLoading}
                RecordsQuantity={RecordsQuantity}
                recordCount={recordCount}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setRecordsQuantity={setRecordsQuantity}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
