/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useToast } from "../../../contexts/toast";
import ApplyAdvance from "./ApplyAdvance";
import ApplyCharge from "./ApplyCharge";
import MakeAjustment from "./MakeAjustment";
import Payment from "./Payment";
import PrintMasterFolio from "./PrintMasterFolio";

const MasterFolioPrincipalBtnGroup = ({
  selectedFolio,
  selectedFolioDetails = [],
  selectedItem,
  searchMasterFolio,
  MasterfolioSelectedItems,
  generalInfo,
  MasterFolioRef,
  masterFolioState,
}) => {
  const toast = useToast();
  const {t} = useTranslation();
  const [selectedFolioDetail, setSelectedFolioDetail] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [showPrintFolio, setShowPrintFolio] = useState(false);
  const [showAdvance, setShowAdvance] = useState(false);
  const [showCharge, setShowCharge] = useState(false);
  const [showAjustment, setShowAjustment] = useState(false);
 
  const togglePrintFolio = () => {
    // console.log(selectedFolio)
    if (selectedFolio === null || selectedFolio === undefined) {
      toast({
        message: t("Please select a folio"),
        type: "error",
        height: "auto",
        width: "auto",
      });
      return;
    }
    setShowPrintFolio(!showPrintFolio);
  };

  const toggleAjustment = (item) => {
    if (selectedFolio === null || selectedFolio === undefined) {
      toast({
        message: t("Please select a folio"),
        type: "error",
        height: "auto",
        width: "auto",
      });
      return;
    }
    // console.log("🚀 ~ toggleAjustment ~ item:", item);
    setSelectedFolioDetail(item);
    setShowAjustment(!showAjustment);

    if (showAjustment) {
      // search(
      //   reservationInfo.Room,
      //   reservationInfo.ReservationNumber,
      //   reservationInfo.GUID
      // );
      searchMasterFolio();
      // if (selectedItem) {
        // searchFolioDetails(
        //   selectedFolio,
        //   reservationInfo.Room,
        //   reservationInfo.ReservationNumber
        // );
      // }
    }
  };

  const toggleApplyAdvance = () => {
    setShowAdvance(!showAdvance);

    if (showAdvance) {
      searchMasterFolio();
      if (selectedFolio) {
      }
    }
  };

  const toggleApplyCharge = () => {
    if (selectedFolio === null || selectedFolio === undefined) {
      toast({
        message: t("Please select a folio"),
        type: "error",
        height: "auto",
        width: "auto",
      });
      return;
    }
    setShowCharge(!showCharge);
    if (showCharge) {
      searchMasterFolio()
      if (selectedFolio) {
        console.log("🚀 ~ toggleApplyCharge ~ selectedFolio:", selectedFolio)
      }
    }
  };

  const closePayment = () => {
    setShowPayment(false);
    if (selectedFolio) {
      searchMasterFolio()
    }
  };

  const togglePayment = () => {
    const selectedFolioDetail = MasterfolioSelectedItems.filter(
      (x) => x.AllowPayment === false
    )[0];
    if (selectedFolioDetail) {
      toast({
        message: t("This record cannot be paid"),
        type: "error",
        height: "auto",
        width: "auto",
      });
      return;
    }
    setShowPayment(!showPayment);
  }

  // useEffect(() => {
  //   console.log("🚀 ~ useEffect ~ selectedFolioDetails:", selectedFolioDetails)
  // },[selectedFolioDetails])

  // const toggleTransfer = () => {
  //   setShowTransfer(!showTransfer);
  //   if (showTransfer) {
  //     searchMasterFolio()
  //   }
  // };

  // const toggleAddFolios = () => {
  //   setShowAddFolios(!showAddFolios);
  // };
  
  const principalButtonList = [
    {
      id: 1,
      title: "Charge",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-calculator",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => toggleApplyCharge(),
      folioDetailSelected: false,
      folioSelected: true,
    },
    {
      id: 1,
      title: "Deposit",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-calculator",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => toggleApplyAdvance(),
      folioDetailSelected: false,
      folioSelected: true,
    },
    {
      id: 2,
      title: "Invoice",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-money-check-dollar",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => togglePayment(),
      folioDetailSelected: true,
      folioSelected: true,
    },
    {
      id: 2,
      title: "Print Folio",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-print",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => togglePrintFolio(),
      folioDetailSelected: false,
      folioSelected: true,
    },
  ];

  return (
    <div>
     
      {showPrintFolio && (
        <PrintMasterFolio
          show={true}
          toggle={togglePrintFolio}
          selectedFolio={selectedFolio}
        />
      )}
      {showAdvance && (
        <ApplyAdvance
          show={true}
          toggle={toggleApplyAdvance}
          parent={generalInfo}
          selectedFolio={selectedFolio}
        />
      )}
      {/* {showTransfer && (
        <Transfer
          show={true}
          toggle={toggleTransfer}
          parent={generalInfo}
          // currentFolios={folios}
          selectedFolioDetails={selectedFolioDetails}
          // selectedReservation={reservationInfo}
          modalSize="lg"
        />
      )} */}
      {showCharge && (
        <ApplyCharge
          show={showCharge}
          toggle={toggleApplyCharge}
          // parent={generalInfo}
          parent={selectedFolio}
        />
      )}
      {showAjustment && (
        <MakeAjustment
          show={showAjustment}
          toggle={toggleAjustment}
          parent={generalInfo}
          selectedFolioDetail={selectedFolioDetail}
          IdBillingService={selectedFolioDetail?.IdBillingService}
        />
      )}
      {showPayment && (
        <Payment
          show={showPayment}
          close={closePayment}
          toggle={togglePayment}
          parent={{GUIDReservationToFolio: selectedFolio?.GUIDReservationToFolio}}
          records={MasterfolioSelectedItems}
          reservation={{GUIDReservation: generalInfo?.GUIDMasterFolio}}
          generalInfo={generalInfo}
        />
      )}
      <div className="btn-group">
        {principalButtonList.map((item, index) => {
          if (
            (item.folioSelected && selectedFolio !== null) &&
            (!item.folioDetailSelected)
          ) {
            return (
              <div key={index} className="d-inline">
                <Button
                  key={item}
                  variant={item.variant}
                  className={item.className + " me-2"}
                  onClick={item.onClick}
                >
                  <i className={item.icon} /> {item.title}
                </Button>
              </div>
            );
          }

          if (
            (item.folioSelected && selectedFolio !== null) &&
            (item.folioDetailSelected && MasterfolioSelectedItems.length > 0)
          ) {

          // if (
          //   item?.reservationCode.length > 0 &&
          //   item?.reservationCode.includes(
          //     selectedItem?.ReservationCode
          //   )
          // ) {
            item.className = "border border-black";
            return (
              <div key={index} className="d-inline">
                <Button
                  key={item}
                  variant={item.variant}
                  className={item.className + " me-2"}
                  onClick={item.onClick}
                >
                  <i className={item.icon} /> {item.title}
                </Button>
              </div>
            );
          }
          return null;
        })}
        {/* <Button variant="primary" onClick={() => console.log({selectedFolio: selectedFolio, selectedFolioDetails: selectedFolioDetails.length, MasterfolioSelectedItems: MasterfolioSelectedItems, masterFolioState})}>
          Check
        </Button> */}
      </div>
    </div>
  )
}

export default MasterFolioPrincipalBtnGroup
