import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Card, Form, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import API from "../../api/api";
import { useToast } from "../../contexts/toast";
import { parsedUser } from "../../utils/GetCurrentUser";

const ReservationRestrictionsDetailsPicker = forwardRef(
  ({ DateStart, DateEnd, GUIDReservationRestriction }, ref) => {
    const { Token, IdUser } = parsedUser();
    const { t } = useTranslation();
    const [records, setRecords] = useState([]);
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRestrictions, setSelectedRestrictions] = useState([]);

    useEffect(() => {
      requestRestrictions();
    }, []);

    const requestRestrictions = async () => {
      setIsLoading(true);
      try {
        const payload = {
          Data: `@Accion='V',@GUIDReservationRestriction='${GUIDReservationRestriction}' ,@DateStart='${DateStart}' ,@DateEnd='${DateEnd}'`,
        };
        const queryParams = `token=${Token}&IdUser=${IdUser}`;
        const request = await API.postAction(
          `api/Restrictions/CRUDpmsReservationRestrictionsDetails?${queryParams}`,
          payload
        );
        const results = request.data[0];
        const parsedResults = JSON.parse(results.JSONData)[0];
        const recordResult = parsedResults.JSONData || [];
        setRecords(recordResult);

        //Check to fill selectedFeatures
        let selected = [];
        recordResult.forEach((item) => {
          if (item.Apply) {
            selected.push(item);
          }
        });
        setSelectedRestrictions(selected);

        if (parsedResults.Error) {
          throw new Error(parsedResults.Msg);
        }
      } catch (error) {
        if (error instanceof Error) {
          toast({ type: "error", message: error.message });
          return;
        }
        toast({ type: "error", message: t("Error loading data") });
        return;
      } finally {
        setIsLoading(false);
      }
    };

    const updatePickedRestriction = async (item) => {
      // console.log("🚀 ~ updatePickedRestriction ~ item:", item)
      setIsLoading(true);
      try {
        const payload = {
          Data: `@Accion='U',@Apply='${
            item.Apply ? 1 : 0
          }' ,@GUIDReservationRestriction='${GUIDReservationRestriction}' ,@GUIDContractsToRatePeriod='${
            item.GUIDContractsToRatePeriod
          }'`,
        };
        const queryParams = `token=${Token}&IdUser=${IdUser}`;
        const request = await API.postAction(
          `api/Restrictions/CRUDpmsReservationRestrictionsDetails?${queryParams}`,
          payload
        );
        const results = request.data[0];
        const parsedResults = JSON.parse(results.JSONData)[0];
        if (parsedResults.Error) {
          throw new Error(parsedResults.Msg);
        }
        toast({
          type: "success",
          message: t("Reservation restriction saved successfully"),
        });
      } catch (error) {
        if (error instanceof Error) {
          toast({ type: "error", message: error.message });
          return;
        }
        toast({
          type: "error",
          message: t("Error updating reservation restriction"),
        });
      } finally {
        await requestRestrictions();
        setIsLoading(false);
      }
    };

    const handleCheckbox = async (item, check) => {
      // console.log("🚀 ~ handleCheckbox ~ item:", item)
      item.Apply = check;
      await updatePickedRestriction(item);
    };

    useImperativeHandle(ref, () => ({
      requestRestrictions() {
        requestRestrictions();
      },
    }));

    return (
      <Card>
        <Card.Body className="p-2">
          <Row className="mx-0">
            <div className="table-responsive">
              <Table border striped hover>
                <thead>
                  <tr>
                    <th>{t("Business Unit")}</th>
                    <th>{t("Rate Period Description")}</th>
                    <th>{t("Rate Code")}</th>
                    <th className="text-center">{t("Apply")}</th>
                  </tr>
                </thead>
                <tbody>
                  {records.length > 0 ? (
                    records.map((item, index) => {
                      console.log("item", item);
                      let checked = selectedRestrictions.filter(
                        (x) => x.Apply === item.Apply
                      )[0];
                      return (
                        <tr key={index}>
                          <td>{item.BusinessUnit}</td>
                          <td>{item.RatePeriodDesciption}</td>
                          <td>{item.RateCode ?? "-"}</td>
                          <td className="text-center">
                            <Form.Check
                              inline
                              disabled={isLoading}
                              type={"checkbox"}
                              checked={checked}
                              onChange={(e) =>
                                handleCheckbox(item, e.target.checked)
                              }
                              // onChange={() => togglePickedFeature(item)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : isLoading ? (
                    <tr>
                      <td colSpan="3" className="text-center py-2">
                        {t("Loading...")}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center py-2">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Row>
        </Card.Body>
      </Card>
    );
  }
);

export default ReservationRestrictionsDetailsPicker;
