import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GenericFields } from '../../group-reservation/GenericFields';
import { useFormik } from 'formik';
import * as yup from 'yup';
import yupTypeValidator from '../../../utils/YupTypeValidator';

const TravelingWithAdd = ({
  showAddEdit,
  toggle,
  bindDataSearch,
  recordsSearch,
  saveRelationship,
  isLoading
}) => {
  const { t } = useTranslation();
  const [validationsSchema, setValidationSchema] = useState(null);
  const [generalError, setGeneralError] = useState(null);

  const fields = [
    {
      FieldName: "Search",
      FieldType: "varchar",
      FieldTitle: t("Search Reservation Number"),
  
      cssClasss: "col-10",
      HtmlType: "text",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: null,
  
      Required: true,
      Visible: true,
      ReadOnly: false,
    },
  ];

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};
    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;
        initialObject[item.FieldName] = item.value;
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;
    setValidationSchema(yup.object(initialObjectValidation));
  };

  const save = () => {
    formik.setFieldValue("Search", "");
    saveRelationship()
  }

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: bindDataSearch,
  });

  useEffect(() => {
    prepareFormikObject();
  },[])

  return (
    <>
      <Modal
        show={showAddEdit}
        onHide={() => toggle()}
        backdrop="static"
        keyboard={false}
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Traveling With")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid className="overflow-auto">
            <Row className="mb-4 mt-2">
              <Form className="row" onSubmit={formik.handleSubmit}>
                <Col xs={10}>
                  <GenericFields
                    section={null}
                    updateFields={updateField}
                    fields={fields}
                    values={formik.values}
                    touched={formik.touched}
                    errors={formik.errors}
                    customLabel={null}
                  />
                </Col>

                <Col xs={2} className="mt-4">
                  <button
                    type="submit"
                    className="btn btn-secondary mt-3"
                    onClick={formik.handleSubmit}
                    disabled={isLoading}
                  >
                    {t("Search")}
                  </button>
                </Col>
              </Form>
              <Col xs={12} className="mt-3">
                <Card>
                  <Card.Body>
                    <table className="table table-striped mt-4">
                      <thead>
                        <tr>
                          {/* <td>Reservation</td> */}
                          <td>{t("Room")}</td>
                          <td>{t("First Name")}</td>
                          <td>{t("Last Name")}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {recordsSearch && recordsSearch.length > 0 ? (
                          recordsSearch
                            .filter((x) => x !== null)
                            .map((item, index) => {
                              console.log(item);
                              return (
                                <tr key={index}>
                                  {/* <td>{item.ReservationNumber}</td> */}
                                  <td>{item.Room}</td>
                                  <td>{item.FirstName}</td>
                                  <td>{item.LastName}</td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td className="border-1 text-center" colSpan={12}>
                              {t("No Records Found")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {generalError ? (
              <Row>
                <Col xs={12}>
                  <div className="alert alert-danger" role="alert">
                    {generalError}
                  </div>
                </Col>
              </Row>
            ) : null}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gx-3 align-items-center justify-content-end">
            {Object.values(formik.errors).length > 0 && (
              <span className="invalid me-2">
                {t("Please check the forms for errors")}
              </span>
            )}
            <button
              className="btn me-2"
              type="button"
              onClick={() => toggle(null)}
            >
              {t("Close")}
            </button>
            <button
              className="btn btn-primary me-2"
              type="button"
              onClick={save}
              disabled={recordsSearch.length === 0}
            >
              {t("Add")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TravelingWithAdd