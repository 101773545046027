import Scheduler, { Editing, Resource, Scrolling } from "devextreme-react/scheduler";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import "./custom-scheduler-style.css";
// import {
  // dataChart,
  // roomData,
  // RoomOccupationJsonFile,
  // dbObj,
// } from "./exampleData";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import API from "../../api/api";
import { useToast } from "../../contexts/toast";
import { parsedUser } from "../../utils/GetCurrentUser";
import { ModalColorPicker } from "../color-picker/ModalColorPicker";
import CashierModal from "../../components/reservations/cashier/CashierModal";

const views = [
  { name: "Months", type: "timelineMonth", intervalCount: 2 },
  {
    name: "Weeks",
    type: "timelineWeek",
    intervalCount: 4,
  },
];

const RoomOccupation = () => {
  const { t } = useTranslation();
  const user = parsedUser();
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [roomsData, setRoomsData] = useState([]);
  // const [search, setSearch] = useState("");
  const [showColorSchema, setshowColorSchema] = useState(false);
  const [showCashier, setShowCashier] = useState(false);
  const groups = ["room"];

  const principalButtonList = [
    {
      id: 1,
      title: t("Cashier"),
      variant: "outline-success",
      className: "",
      icon: "fa-solid fa-cash-register",
      onClick: () => toggleCashier(),
    },
    {
      id: 2,
      title: t("Groups"),
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-people-group",
      onClick: () => navigate("/process/group-reservations"),
    },
    {
      id: 3,
      title: t("Room Map"),
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-folder-tree",
      onClick: () => navigate("/process/room-map"),
    },
    {
      id: 4,
      title: t("Reload"),
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-rotate",
      onClick: () => reload(),
    },
    {
      id: 5,
      title: t("Color Scheme"),
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-circle-question",
      onClick: () => toggleColorScheme(),
    }
  ];

  const toggleColorScheme = () => {
    setshowColorSchema(!showColorSchema);
  };

  const reload = async () => {
    await bindDataRemote({startDate: moment().format('MM-DD-YYYY'), endDate: moment().add(4, 'weeks').format('MM-DD-YYYY')});
  }

  // const handleChange = (e) => {
  //   setSearch(e.target.value);
  // };

  // const handleSubmit = async (evt) => {
    // evt.preventDefault();
    // if (roomsData === null) return;
    // if (search === "") {
    //   await bindDataRemote();
    //   return;
    // }
    // setRoomsData(
    //   roomsData &&roomsData.filter((val) => {
    //     return val?.text?.toLowerCase().includes(search?.toLowerCase());
    //   })
    // );
  // };

  useEffect(() => {
    bindDataRemote({startDate: moment().format('MM-DD-YYYY'), endDate: moment().add(4, 'weeks').format('MM-DD-YYYY')});
  }, [])

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ roomsData", roomsData)
  },[roomsData])

  const handleDateChange = async (date) => {
    const currentDate = moment(date).format("MM-DD-YYYY");
    const endDate = moment(date).add(4, "weeks").format("MM-DD-YYYY");
    await bindDataRemote({startDate: currentDate, endDate: endDate});
    // console.log({startDate: currentDate, endDate: endDate});
  }

  const bindDataRemote = async (filter) => {
    setIsLoading(true)
    const { startDate, endDate } = filter;

    try {
      const queryString = `IdUser=${user.IdUser}&token=${user.Token}`;
      const queryData = {
        Data: `@IdBusinessUnit=${user.IdBusinessUnit}, @StartDate='${startDate}', @EndDate='${endDate}'`,
      };
      const request = await API.postAction(
        "/api/Rooms/RoomOccupation?" + queryString,
        queryData
      );
      const results = await JSON.parse(request.data[0].JSONData);
      console.log("🚀 ~ bindDataRemote ~ results:", results)
      console.log("🚀 ~ bindDataRemote ~ results:", results[0].rooms.rooms)
      console.log("🚀 ~ bindDataRemote ~ results:", results[0].dataChart.dataChart)
      if (results === null) {
        setRecords([]);
        setRoomsData([]);
        return;
      }
      // const parsedRoomData = results[0].rooms.rooms.map((item) => {
      //   return {
      //     ...item,
      //     id: item.text
      //   };
      // });
      // setRoomsData(parsedRoomData);
      setRoomsData(results[0].rooms.rooms.map((item) => {
        return {
          ...item,
          id: item.text
        };
      }));
      const parsedDatachart = results[0].dataChart.dataChart.map((item) => {
        const startDate = moment(item.startDate).tz("America/Santo_Domingo").hour(15).minute(0).toDate();
        const endDate = moment(item.endDate).tz("America/Santo_Domingo").hour(13).minute(0).toDate();
        
        console.log({startDate, endDate});
        return {
          ...item,
          startDate,
          endDate
        };
      });
      setRecords(parsedDatachart);
      if (results === null) {
        toast({
          title: "No data found",
          status: "error",
        });
        throw new Error("No data found");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const appointmentTemplate = (model) => {
    console.log("🚀 ~ appointmentTemplate ~ model:", model)
    const { appointmentData } = model;
    return (
      <div
        className=""
        style={{
          backgroundColor: appointmentData.reservationColor,
          color: appointmentData.reservationTextColor,
          fontWeight: "bold",
          padding: "0px",
          // height: "100%",
        }}
      >
        <div className="">{appointmentData.text} - {appointmentData.room} </div>
      </div>
    );
  };

  const renderGroupCell = (props) => {
    return (
      <div className="d-flex gap-0 h-100 align-items-center" style={{backgroundColor: props.data.backgroundColor, width: '125px'}}>
        <div
          className="d-flex aling-items-center justify-content-center text-center w-100 gap-2"
          style={{
            backgroundColor: props.data.backgroundColor,
            color: props.data.color,
          }}
        >
          <span className="fw-bold text-center">
            {props.data.text}
          </span>
          <span>
            {props.data.roomType}
          </span>
        </div>
        {/* <div className="mx-2"
          style={{
            backgroundColor: props.data.backgroundColor,
            color: props.data.color,
            fontWeight: "bold",
            width: '100%'
          }}
        >{props.data.roomType}</div> */}
      </div>
    );
  };

  const renderDateCell = useCallback((itemData) => {
    const isWeekend =
      itemData.date.getDay() === 0 || itemData.date.getDay() === 6;
    const classCustom = isWeekend
      ? "pt-3 w-100 fw-bolder text-dark"
      : "text-dark";
    const styleCustom = isWeekend ? { backgroundColor: "#FFB0B0" } : {};
    const date = itemData.date;

    // Obtener el nombre del día (por ejemplo, 'Lunes')
    const dayName = new Intl.DateTimeFormat("en", {
      weekday: "short",
    }).format(date);

    // Obtener el número del día del mes
    const dayNumber = date.getDate();

    /* <div className={backgroundColor}>
        <div>{itemData.text}</div>
      </div> */

    return (
      <>
        <div className={classCustom} style={styleCustom}>
          <span class="dx-scheduler-header-panel-cell-date ">{dayName}</span>
          <span class="dx-scheduler-header-panel-cell-date fs-3">
            {dayNumber}
          </span>
        </div>
      </>
    );
  }, []);

  const onClick = (e) => {
    console.log(e);
    return;
  }

  
  const toggleCashier = () => {
      setShowCashier(!showCashier);
  };

  return (
    <>
      <ModalColorPicker
        show={showColorSchema}
        handleClose={() => toggleColorScheme()}
      />
      {showCashier && (
        <CashierModal
          show={showCashier}
          setShow={setShowCashier}
          // reservation={selectedItem}
        />
      )}
      <Card bg="transparent" border="0">
        <Card.Body className="ps-0 pt-0 pb-3">
          <Row>
            <Col xs={12} sm={6} xl={9}>
            <div className="d-flex w-100 my-2 mt-0 justify-content-start gap-1">
            {principalButtonList.map((item, index) => {
              return (
                <>
                  <Button
                    key={item}
                    variant={item.variant}
                    size="lg"
                    className={item.className}
                    onClick={item.onClick}
                  >
                    {t(item.title)}
                    <br />
                    <i className={item.icon} />
                  </Button>
                </>
              );
            })}
            </div>
            </Col>
            <Col xs={12} sm={6} xl={3} className="text-end">
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Scheduler
        id={'scheduler'}
        timeZone="America/Santo_Domingo"
        startDayHour={0}
        // dataSource={myCustomStore}
        dataSource={records || []}
        // dataSource={Array.isArray(records) ? records : []}
        views={views}
        // currentDate={new Date()}
        defaultCurrentView={"Weeks"}
        height={'75vh'}
        groups={groups}
        cellDuration={1440}
        firstDayOfWeek={0}
        groupByDate={false}
        timeCellComponent={null}
        resourceCellRender={renderGroupCell}
        showAllDayPanel={false}
        appointmentRender={appointmentTemplate}
        showCurrentTimeIndicator={false}
        maxAppointmentsPerCell={1}
        shadeUntilCurrentTime={false}
        dateCellRender={renderDateCell}
        // remoteFiltering={true}
        // disabled={isLoading}
        onCellClick={onClick}
        onAppointmentClick={onClick}
        onAppointmentDblClick={onClick}
        onAppointmentFormOpening={onClick}
        onOptionChanged={(e) => {
          if (e.name === "currentDate") {
            // if (isLoading) return;
            // console.log(e);
            handleDateChange(e.value);
            // setCurrentView(e.value);
          }
        }
        }
      >
        <Editing
          allowAdding={false}
          allowDeleting={false}
          allowResizing={false}
          allowDragging={false}
          allowUpdating={false}
        />
        <Resource
          fieldExpr="room"
          allowMultiple={true}
          dataSource={roomsData}
          label={t("Room")}
          useColorAsDefault={false}
        />

        <Scrolling mode="virtual" />
      </Scheduler>
    </>
  );
};

export default RoomOccupation;
