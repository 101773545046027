import React, { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import './fixed-table-style.css'
// import { RoomMapData as ExampleData } from './RoomMapExampleData'

const RoomMapContainer = ({ records }) => {
  const [RoomMapData, setRoomMapData] = useState([])

  useEffect(() => {
    if (records) {
      setRoomMapData(records)
    }
  }, [records])

  if (!records) {
    return <div>Loading...</div>
  }

  return (
    <div className="d-flex flex-wrap justify-content-between align-items-center mb-2 mt-2 w-auto">
      {RoomMapData && RoomMapData.length > 0 && RoomMapData?.map((item, index) => {
        // console.log({item})
        return (
          <Card className="w-100 mb-4" key={`${index}-${item?.name}`}>
            <Card.Header className="bg-primary-subtle">
              <h5 className="m-0">{item?.Building}</h5>
            </Card.Header>
            <Card.Body style={{ overflow: 'auto' }}>
              <div
                className='wrapper w-auto'
              >
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th className='sticky-col first-col text-center'>Floor</th>
                      <th colSpan={100} className="text-center">Rooms</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.floors && item?.floors?.length > 0 ? item?.floors.map((suite, suiteIndex) => {
                      return (
                        <tr key={`${suiteIndex}-${suite.Id}`}>
                          <td className='sticky-col first-col'>{suite.floorNumber}</td>
                          {(suite.rooms && suite.rooms.length > 0) ? suite.rooms.map((room, index) => <td key={`${index}-${room.Room}`} className={`text-center`} style={{ backgroundColor: room.bgColor, color: room.txtColor }}>{room.Room}</td>) : <td colSpan={100} className="text-center">No rooms found</td>}
                        </tr>
                      )
                    }) : (
                      <tr>
                        <td colSpan={100} className="text-center">No rooms found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        )
      })}
    </div>
  )
}

export default RoomMapContainer
