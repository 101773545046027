import { t } from "i18next";

const budgetFilterFields = [
  {
    FieldName: "IdBudgetSource",
    FieldType: "bigint",
    FieldTitle: t("Source"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"d256560a48149866845e11ebf298b955d03805e51e6f69a90a0a2021008eb6a8"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
  {
    FieldName: "IdBudgetType",
    FieldType: "bigint",
    FieldTitle: t("Type"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"a05708c65b34852ea7a61242adc50f63748c5008b84b1e6d210ff8909710cd90"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },

  {
    FieldName: "IdCustomer",
    FieldType: "bigint",
    FieldTitle: t("Customer"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"846913eebc9b8aa7911a0eadac6ef961ed83fe2cad71fdf1d46d5770f889e56a"}',
    Required: true,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
  {
    FieldName: "IdDepartment",
    FieldType: "bigint",
    FieldTitle: t("Department"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource:
      '{"HashID":"1d09f3e1745aa57503217edfa59c5665bd8ea5d0f1d19e3427538ac67b854080"}',
    Required: false,
    Visible: false,
    ReadOnly: false,
    Group: 0,
  },

  {
    FieldName: "BudgetYear",
    FieldType: "bigint",
    FieldTitle: t("Year"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: "",
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    Group: 0,
  },
];

export default budgetFilterFields;
