import { HighlightOff, Visibility } from "@mui/icons-material";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { handleFilter } from "../../utils/filter-utils";
import FieldSelector from "../customComponents/FieldSelector";
import {
  // ReservationAvailabilityDataSetDetailed,
  // ReservationAvailabilityDataSetNoDetailed,
  ReservationAvailabiltyFilterFields,
} from "./fields/ReservationAvailablityFields";

import { useTranslation } from "react-i18next";
import CustomReportsDataGrid from "../report/CustomReportsDataGrid/CustomReportsDataGrid";
import ReportFilter from "../report/Filter/ReportFilter";

const ReservationAvailability = (props) => {
  const { t } = useTranslation();

  // const dataSet = ReservationAvailabilityDataSetNoDetailed;
  // const dataSetDetailed = ReservationAvailabilityDataSetDetailed;
  const filters = ReservationAvailabiltyFilterFields;
  const user = parsedUser();
  const { show, handleClose } = props;
  const [showReport, setShowReport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [summary, setSummary] = useState([]);
  const datagridRef = useRef(null);

  const generateReport = useCallback(async (obj) => {
    clearReport();
    // console.log(datagridRef.current);
    try {
      const ObjVersion = await handleFilter(obj, filters);
      await bindDataRemote(ObjVersion);
    } catch (error) {
      console.log(error);
    }
  }, [filters]);

  const clearReport = () => {
    try {
      setShowReport(false);
    } catch (error) {
      console.log(error);
    }
  };

  const bindDataRemote = async (filter) => {
    setIsLoading(true);
    const filterData = filter ? `${filter}, @IdBusinessUnit=${user.IdBusinessUnit}` : `@IdBusinessUnit=${user.IdBusinessUnit}`;
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryData = {
        Data: filterData,
      };
      let request = await API.postAction(`/api/Reservations/RoomTypeSpaceChart?` + query, queryData ?? "");
      let results = await request.data[0];
      let parsedResults = JSON.parse(results.Column1);
      const recordResult = parsedResults?.Records;
      const headerResult = parsedResults?.Headers;
      const summary = parsedResults?.Summary;
      // console.log("🚀 ~ bindDataRemote ~ recordResult:", recordResult)
      // console.log("🚀 ~ bindDataRemote ~ headerResult:", headerResult)
      // console.log("🚀 ~ bindDataRemote ~ summary:", summary)
      setHeaders(headerResult);
      setRecords(recordResult);
      setSummary(summary);
      setShowReport(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
      keyboard={false}
      size="xl"
      dialogClassName="modal-90w"
      className="modalRight z-3"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Room Type Space Chart")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mx-2">
          <Col className="col-12">
            <ReportFilter
              filterFields={filters}
              isLoading={isLoading}
              generateReport={generateReport}
              clearReport={clearReport}
            />
          </Col>
          <Col className="col-12">
            {showReport && (
              <CustomReportsDataGrid
                records={records}
                summary={summary}
                headers={headers}
              />
            )}
          </Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose(false)}>Close</button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default ReservationAvailability;

// const HeaderFilter = ({
//   filterFields,
//   isLoading,
//   generateReport,
//   clearReport,
// }) => {
//   const { t } = useTranslation();

//   const [filters] = useState(filterFields);
//   const [validationsSchema, setValidationSchema] = useState(null);
//   const formik = useFormik({
//     initialValues: {},
//     enableReinitialize: true,
//     validateOnChange: false,
//     validateOnBlur: false,
//     validationSchema: validationsSchema,
//     onSubmit: generateReport,
//     onReset: clearReport,
//   });

//   useEffect(() => {
//     prepareFormikObject();
//   }, [filters]);

//   const updateField = (fieldName, fieldValue) => {
//     formik.setFieldValue(fieldName, fieldValue);
//   };

//   const prepareFormikObject = () => {
//     let initialObject = {};
//     let initialObjectValidation = {};

//     if (filters) {
//       filters.forEach((item) => {
//         initialObject[item.FieldName] = item.value;
//         if (item.Required) {
//           initialObjectValidation[item.FieldName] = yupTypeValidator(item);
//         }
//       });
//     }
//     formik.initialValues = initialObject;
//     setValidationSchema(yup.object(initialObjectValidation));
//   };

//   return (
//     <Card>
//       <Card.Body>
//         <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
//           <Row>
//             {filters &&
//               filters.map((item, index) => {
//                 return (
//                   <Col
//                     className={
//                       item.cssClasss + (!item.Visible ? " d-none" : "")
//                     }
//                     key={index}
//                   >
//                     <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
//                     {item.Required ? (
//                       <i className="required-asterisk ms-1 text-danger">*</i>
//                     ) : null}
//                     <br />

//                     <FieldSelector
//                       model={item}
//                       key={index}
//                       updateField={updateField}
//                       value={formik.values[item.FieldName]}
//                     ></FieldSelector>
//                     {formik.errors[item.FieldName] ? (
//                       <div className="invalid text-sm">
//                         {t(formik.errors[item.FieldName])}
//                       </div>
//                     ) : null}
//                   </Col>
//                 );
//               })}

//             <Col className="col-12 d-flex justify-content-end gap-2 mt-3">
//               <button
//                 className="btn btn-sm btn-outline-secondary me-2"
//                 type="reset"
//                 title={t("Clear")}
//                 disabled={isLoading}
//               >
//                 <HighlightOff /> {t("Clear")}
//               </button>
//               <button
//                 className="btn btn-sm btn-primary"
//                 title={t("Search")}
//                 type="submit"
//                 disabled={isLoading}
//               >
//                 <Visibility /> {t("Preview")}
//               </button>
//             </Col>
//           </Row>
//         </Form>
//       </Card.Body>
//     </Card>
//   );
// };

// const CustomDataGrid = (props) => {
//   const { t } = useTranslation();

//   const { records, summary, headers } = props;

//   const GroupCell = (options) => <div>{t(options.value)}</div>;

//   return (
//     <DataGrid
//       dataSource={records}
//       columnAutoWidth={true}
//       showBorders={true}
//       className="grid"
//       loadPanel={{
//         enabled: true,
//         showIndicator: true,
//         text: t("Loading") + "...",
//       }}
//     >
//       <ColumnFixing enabled={true} />
//       <Grouping autoExpandAll={true} />
//       <GroupPanel visible={false} />
//       <Summary>
//         {summary?.Group &&
//           summary?.Group.map((item, index) => {
//             // console.log(item);
//             return (
//               <GroupItem
//                 key={index}
//                 column={item?.FieldName}
//                 summaryType={item?.SummaryType}
//                 displayFormat={item?.DisplayFormat}
//                 valueFormat={item?.FormatValue}
//                 showInColumn={item?.DisplayInColumn}
//                 showInGroupFooter={item?.DisplayInGroupFooter}
//               />
//             );
//           })}
//         {summary?.Total &&
//           summary?.Total.map((item, index) => {
//             // console.log(item);
//             return (
//               <TotalItem
//                 key={index}
//                 column={item?.FieldName}
//                 summaryType={item?.SummaryType}
//                 displayFormat={item?.DisplayFormat}
//                 valueFormat={item?.FormatValue}
//                 showInColumn={item?.DisplayInColumn}
//               />
//             );
//           })}
//       </Summary>
//       <Selection mode="single" />
//       {headers &&
//         headers.map((item, index) => {
//           if (item.FieldType === "int") {
//             // console.log(item);
//           }
//           if (item.SubColumns) {
//             return (
//               <Column
//                 className={
//                   item.FieldType === "int" && item.FieldTitle * 1 < 0
//                     ? "text-danger"
//                     : ""
//                 }
//                 key={index}
//                 caption={item?.FieldTitle}
//                 dataField={item?.FieldName}
//                 wordWrapEnabled={true}
//                 groupIndex={item?.groupIndex}
//                 groupCellRender={GroupCell}
//                 format={item?.FormatValue}
//                 alignment={"center"}
//               >
//                 {item.SubColumns.map((subItem, subIndex) => {
//                   return (
//                     <Column
//                       key={subIndex}
//                       caption={subItem?.FieldTitle}
//                       dataField={subItem?.FieldName}
//                       wordWrapEnabled={true}
//                       groupIndex={subItem?.groupIndex}
//                       groupCellRender={GroupCell}
//                       format={subItem?.FormatValue}
//                       // alignment={"center"}
//                     />
//                   );
//                 })}
//               </Column>
//             );
//           }
//           if (item.FieldType === "timestamp") {
//             return (
//               <Column
//                 className={
//                   item.FieldType === "int" && item.FieldTitle * 1 < 0
//                     ? "text-danger"
//                     : ""
//                 }
//                 key={index}
//                 caption={item?.FieldTitle}
//                 dataField={item?.FieldName}
//                 dataType={"date"}
//                 wordWrapEnabled={true}
//                 groupIndex={item?.groupIndex}
//                 groupCellRender={GroupCell}
//                 format={dateFormater}
//               />
//             );
//           }

//           return (
//             <Column
//               className={
//                 item.FieldType === "int" && item.FieldTitle * 1 < 0
//                   ? "text-danger"
//                   : ""
//               }
//               caption={item?.FieldTitle}
//               key={index}
//               dataField={item?.FieldName}
//               wordWrapEnabled={true}
//               groupIndex={item?.groupIndex}
//               groupCellRender={GroupCell}
//               format={item?.FormatValue}
//             />
//           );
//         })}
//       <Paging defaultPageSize={50} />
//       <Pager
//         showInfo={true}
//         infoText={t("Page") + ":"}
//         visible={true}
//         showPageSizeSelector={true}
//         allowedPageSizes={allowedPageSizes}
//       />
//     </DataGrid>
//   );
// };
