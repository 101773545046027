/**
* @param {number} value
* @returns {string}
* @description Convert a number to a currency format
* @example
* USDollar(1000) // "$1,000.00"
* USDollar(100) // "$100.00"
* USDollar(10) // "$10.00"
* USDollar(1) // "$1.00"
* USDollar(0) // "$0.00"
* USDollar(0.1) // "$0.10"
* USDollar(0.01) // "$0.01"
* USDollar(0.001) // "$0.00"
*/
export const USDollar = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
}


