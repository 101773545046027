import React from "react";
import { Card, Container } from 'react-bootstrap';
import { CustomBreadcrumbs as Breadcrumbs } from '../../components';
import RoomOccupation from "../../components/reservations/roomOccupation";

const RoomOccupationPage = () => {

  return (
    <React.Fragment>
      <Breadcrumbs pathTitle={'Front-Desk / Front-Desk / Room Occupation'} />
      <Container>
        <div className="row mx-0" >
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              <React.Fragment>
                <div className="d-flex align-items-center my-1">
                  <i className="fa-solid fa-home me-3 fa-2x" />
                  <div className="d-flex flex-column">
                    <h5 className={"my-1"}>Room Occupation</h5>
                  </div>
                </div>
              </React.Fragment>
              <div className="w-auto d-flex align-items-center">
              </div>
            </div>
            <RoomOccupation />
            {/* <Card>
              <Card.Body>
              </Card.Body>
            </Card> */}
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default RoomOccupationPage;