/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row, Button } from "react-bootstrap";

import { useFormik } from "formik";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import * as yup from "yup";
import FieldSelector from "../../customComponents/FieldSelector";
import { parsedUser } from "../../../utils/GetCurrentUser";

import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";

import { useTranslation } from "react-i18next";
import { GenericFields } from "../../group-reservation/GenericFields";
import { roomColor } from "../../../utils/custom-cell";


const ShareRoom = (props) => {
  const { t } = useTranslation();
  const user = parsedUser();
  const toast = useToast();
  const [generalError, setGeneralError] = useState(null);
  const [validationsSchema, setValidationSchema] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [records, setRecords] = useState([]);
  const [ChildRecord, setChildRecord] = useState(null);

  const fields = [
    {
      FieldName: "ParentReservationNumber",
      FieldType: "bigint",
      FieldTitle: t("Parent Reservation Number"),
      cssClasss: "col-6 ",
      HtmlType: "text",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource: "",
      Required: false,
      Visible: true,
      ReadOnly: false,
    },
    {
      FieldName: "ChildReservationNumber",
      FieldType: "varchar",
      FieldTitle: t("Child Reservation"),
      cssClasss: "col-6 ",
      HtmlType: "text",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource: null,
      Required: true,
      Visible: true,
      ReadOnly: true,
      GroupBy: 0,
    },
  ];

  const search = async () => {
    setHasError(false);
    const ReservationNumber = formik.values.ParentReservationNumber;

    // ,@ReservationNumber = 1105 --> número de reserva padre que se quiere asignar
    // ,@IdBusinessUnit = 6     --->  Este valor es de la reserva que se quiere asignar como hija

    const filter = {
      Data: `@Accion='S',@ReservationNumber=${ReservationNumber},@IdBusinessUnit=${props.reservation?.IdBusinessUnit}`,
    }

    try {
      const queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;
      const request = await API.postAction(
        `api/Reservations/reservationshareroom?${queryString}`, filter
      );

      const response = JSON.parse(request.data[0].JSONData)[0];
      if (response.Error) {
        setHasError(true);
        toast({
          type: "error",
          message: response.Msg,
          width: 'auto',
          height: 'auto',
        })
        return;
      }
      
      setRecords(response.JSONData);

    } catch (error) {
      toast({
        type: "error",
        message: t("An error occurred while trying to fetch the data"),
        width: 'auto',
        height: 'auto',
      })
      console.error(error);
    }
  };

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  // const fillFormikObject = (record) => {
  //   if (fields && fields.length > 0) {
  //     fields.forEach((item) => {
  //       formik.setFieldValue(item.FieldName, record[item.FieldName]);
  //     });
  //   }

  //   //Now
  // };

  // const prepareFormikObject = () => {
  //   let initialObject = {};
  //   let initialObjectValidation = {};

  //   if (fields) {
  //     fields.forEach((item) => {
  //       if (item.FieldName === "Id" || item.FieldName === "File") return;

  //       initialObject[item.FieldName] = item.value;

  //       if (item.Required) {
  //         initialObjectValidation[item.FieldName] = yupTypeValidator(item);
  //       }
  //     });
  //   }

  //   formik.initialValues = initialObject;

  //   setValidationSchema(yup.object(initialObjectValidation));
  //   //Now
  // };

  const HandleAssign = async (obj, event) => {
    // console.log("object to save", obj);
    // let reservation = props.reservation.ReservationGUID;
    // if (!props.reservation.ReservationGUID) {
    //   reservation = props.reservation.GUID;
    // }

    // @Accion  = 'A'
    // ,@ParentReservationGUID  = '' 
    // ,@ChildReservationGUID = '' 


    const filter = {
      Data: `@Accion='A',@ParentReservationGUID='${records[0].GUIDReservation}',@ChildReservationGUID='${ChildRecord.GUID}'`,
    }
    // console.log(filter);
    // console.log(ChildRecord.GUID);
    // return;
    try {
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;

      let request = await API.postAction(
        `api/Reservations/reservationshareroom?${queryString}`, filter
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: t("Record saved successfully"),
      });

      props.toggle();
    } catch (error) {
      toast({
        type: "error",
        message: t("An error occurred while trying to save the data"),
        width: 'auto',
        height: 'auto',
      })
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: HandleAssign,
  });

  useEffect(() => {
    setChildRecord(props.reservation);
    formik.setFieldValue("ChildReservationNumber", props.reservation.ReservationNumber);
  },[])

  return (
    <>

      <Modal
        show={true}
        onHide={props.toggle}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Share Room ")}</Modal.Title>

        </Modal.Header>

        <Modal.Body>
          <Container fluid className="overflow-auto table-responsive">
            <Row>
              <GenericFields
                fields={fields}
                values={formik.values}
                touched={formik.touched}
                errors={formik.errors}
                updateFields={updateField}
                customLabel={{ status: false }}
                section={null}
              />
              <Col xs={12} className="mt-3 text-start">
                <Button
                  type="submit"
                  variant="secondary"
                  size="sm"
                  onClick={() => search()}
                >
                  Search
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  className="ms-2"
                  size="sm"
                  onClick={() => {
                    updateField("ParentReservationNumber", null);
                  }}
                >
                  <i className="fas fa-eraser"></i>
                </Button>
              </Col>
            </Row>
            <hr />
            <Row className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>{t("Reservation")}</th>
                    <th>{t("Guest")}</th>
                    <th>{t("Room")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    records.map((record, index) => (
                      <tr key={index}>
                        <td>{record.ReservationNumber}</td>
                        <td>{record.Guest}</td>
                        <td className="sticky-col first-col">
                            <span
                              className="badge w-100"
                              style={roomColor({
                                RoomStatusBackgroundColor: record.RoomStatusBgColor,
                                RoomStatusTextColor: record.RoomStatusTxtColor,
                              })}
                            >
                              {record.Room}
                            </span>
                          </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {/* <button className="btn btn-success me-2" type="button" onClick={() => bindDataRemote()}>
            Search
          </button> */}
          <button className="btn btn-primary me-2" type="button" onClick={() => formik.handleSubmit()} disabled={hasError}>
            Share
          </button>
        </Modal.Footer>

      </Modal>
    </>
  );
};

export default ShareRoom;
