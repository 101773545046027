import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";
import { parsedUser } from "../../../utils/GetCurrentUser";
import FieldSelector from "../../customComponents/FieldSelector";
import { USDollar } from "../../../utils/number-to-currency";
import { useTranslation } from "react-i18next";

const Transfer = (props) => {
  const user = parsedUser();
  const { t } = useTranslation();

  const [currentReservation, setCurrentReservation] = useState(null);
  const [reservationGeneralInfo, setReservationGeneralInfo] = useState(null);
  const [records, setRecords] = useState([]);
  const [reason, setReason] = useState({ Comment: "" });

  const toast = useToast();
  const [generalError, setGeneralError] = useState(null);

  const [validationsSchema, setValidationSchema] = useState(null);

  const bindDataRemote = async () => {};
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };
  const [selectedFolio, setSelectedFolio] = useState(null); // same reservation
  const [selectedFolioInReservation, setSelectedFolioInReservation] =
    useState(null); // another reservation and folio

  const handleSubmit = async (obj, event) => {
    console.log("object to save", obj);
    if (obj.Amount <= 0 || !obj.Amount) {
      setGeneralError("Amount must be greater than 0");
      toast({
        type: "error",
        message: "Amount must be greater than 0",
      });
      return;
    }

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryData = {
        Data: `@DetailReferences=${
          obj.DetailReferences ? "'" + obj.DetailReferences + "'" : "null"
        }, @IdBillingService=${
          obj.IdBillingService ? obj.IdBillingService : "null"
        }, @Amount=${obj.Amount}, @GUIDReservationToFolio='${
          props.parent.GUIDReservationToFolio
        }'`,
      };
      // let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.postAction(
        `api/cashier/ApplyCharge?` + query,
        queryData ?? ""
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: "Record saved successfully",
      });

      props.toggle();
    } catch (error) {
      console.error(error);
    }
  };

  const doSearch = async () => {
    let query = `IdUser=${user.IdUser}&token=${user.Token}`;
    let queryData = {
      Data: `@ReservationNumber=${
        formik.values.ReservationNumber ? formik.values.ReservationNumber : "0"
      }`,
    };
    let requestGeneralInfo = await API.postAction(
      `api/cashier/GetReservationGeneralInfo?` + query,
      queryData ?? ""
    );

    console.log(requestGeneralInfo);

    if (requestGeneralInfo.status === 200) {
      let response = JSON.parse(requestGeneralInfo.data[0].JSONData)[0];
      console.log(response);
      if (response.Error) {
        setGeneralError(response.Msg);
        return;
      }
      if (response.JSONData === null) {
        toast({
          type: "error",
          message: "No records found with this number",
        });
        return;
      }
      let theReservation = response.JSONData[0];
      setReservationGeneralInfo(theReservation);

      //Now let's request folios for this reservation
      let queryFolio = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryDataFolio = {
        Data: `@GUIDReservation='${theReservation.GUIDReservation}'`,
      };
      let requestFolios = await API.postAction(
        `api/cashier/GetFolios?` + queryFolio,
        queryDataFolio ?? ""
      );

      console.log(requestFolios);
      if (requestFolios.status === 200) {
        let response2 = JSON.parse(requestFolios.data[0].JSONData)[0];
        console.log(response2);
        if (response2.Error) {
          setGeneralError(response2.Msg);
          return;
        }

        setRecords(response2.JSONData);
      }
    }
  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });
  const fields = [
    {
      IdAppForm: 64,
      Id: 6400005,
      FieldName: "ReservationNumber",
      FieldType: "int",
      FieldTitle: "Reservation #",
      Position: 1,
      cssClasss: "col-12 col-md-6",
      HtmlType: "number",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: null,
      DataSource: null,
      Required: false,
      Visible: true,
      ReadOnly: false,
      Group: 0,
    },
  ];

  const handleSubmitToSameReservation = async () => {
    if (!reason.Comment) {
      toast({
        type: "error",
        message: t("Please provide a reason for the transfer"),
      });
      return;
    }
    console.log(selectedFolio);
    let items = "";
    if (props.selectedFolioDetails.length > 0) {
      props.selectedFolioDetails.forEach((item) => {
        console.log(item);
        items += `${items.length > 0 ? "," : ""}${
          item.IdReservationToFolioDetail
        }`;
      });
    }

    let queryFolio = `IdUser=${user.IdUser}&token=${user.Token}`;
    let queryDataFolio = {
      Data: `@GUIDReservationToFolio ='${selectedFolio.GUIDReservationToFolio}', @ListFolioDetails='${items}', @Comment='${reason.Comment}'`,
    };
    let request = await API.postAction(
      `api/cashier/Transfer?` + queryFolio,
      queryDataFolio ?? ""
    );
    console.log(request);

    toast({
      type: "success",
      message: "Record saved successfully",
    });

    props.toggle();
  };

  const handleSubmitToAnotherReservation = async () => {
    // CHeck if reason is empty
    if (!reason.Comment) {
      toast({
        type: "error",
        message: t("Please provide a reason for the transfer"),
      });
      return;
    }
    console.log(selectedFolio, selectedFolioInReservation);
    let items = "";
    if (props.selectedFolioDetails.length > 0) {
      props.selectedFolioDetails.forEach((item) => {
        console.log(item);
        items += `${items.length > 0 ? "," : ""}${
          item.IdReservationToFolioDetail
        }`;
      });
    }

    let queryFolio = `IdUser=${user.IdUser}&token=${user.Token}`;
    let queryDataFolio = {
      Data: `@GUIDReservationToFolio ='${selectedFolioInReservation.GUIDReservationToFolio}', @ListFolioDetails='${items}', @Comment='${reason.Comment}'`,
    };
    let request = await API.postAction(
      `api/cashier/Transfer?` + queryFolio,
      queryDataFolio ?? ""
    );
    console.log(request);

    toast({
      type: "success",
      message: "Record saved successfully",
    });

    props.toggle();
  };

  useEffect(() => {
    setCurrentReservation(props.selectedReservation);
    bindDataRemote();
  }, [props.selectedReservation]);
  // console.log(records)

  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
      size={props.modalSize ?? "sm"}
      // className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>Transfer</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container fluid className="overflow-auto table-responsive">
          <Row className="justify-content-end">
            <Col>
              <Form className="px-2">
                <Row>
                  <Col xs={2} className="me-2">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Res#
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={currentReservation?.ReservationNumber}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2} className="me-2">
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Qty
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={props?.selectedFolioDetails.length ?? 0}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Amount
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={USDollar(
                          props.selectedFolioDetails.reduce(
                            (acc, item) => acc + item.Amount,
                            0
                          ) ?? 0
                        )}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* Reason */}
                  <Col xs={12}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={2}>
                        Reason
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={reason.Comment}
                        onChange={(e) => setReason({ Comment: e.target.value })}
                        name="Comment"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <hr />
          <Row className="mb-2">
            <Col>
              <Tabs defaultActiveKey={"toFolio"}>
                <Tab
                  eventKey={"toFolio"}
                  title={"To Folio"}
                  className="bg-white border px-2 pt-4"
                >
                  <Row className="px-2">
                    <Col xs={12}>
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              {/* <th className='text-nowrap text-center'>Select</th> */}
                              <th className="text-nowrap">Folio</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.currentFolios.length > 0 &&
                              props.currentFolios.map((item, index) => {
                                console.log(item);
                                return (
                                  <tr
                                    key={index}
                                    onClick={() => setSelectedFolio(item)}
                                    className={
                                      (selectedFolio &&
                                      selectedFolio.GUIDReservationToFolio ===
                                        item?.GUIDReservationToFolio
                                        ? "active"
                                        : "") +
                                      " " +
                                      (selectedFolio === item ? "bg-info" : "")
                                    }
                                  >
                                    {/* <td className='text-nowrap text-center'>
                                      <input type="checkbox" />
                                    </td> */}
                                    <td>{item.FolioType}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                    <Col xs={12} className="text-end mb-2">
                      <button
                        className="btn btn-primary me-2"
                        type="button"
                        disabled={selectedFolio === null}
                        onClick={() => handleSubmitToSameReservation()}
                      >
                        Move
                      </button>
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey={"toReservation"}
                  title={"To Reservation"}
                  className="bg-white border px-2 pt-4"
                >
                  <Row className="px-2">
                    {fields.map((item, index) => (
                      <Col
                        className={
                          item.cssClasss + (!item.Visible ? " d-none" : "")
                        }
                        key={index}
                      >
                        <label className="me-2 ">{item.FieldTitle}</label>
                        {item.Required ? (
                          <i className="required-asterisk ms-1 text-danger">
                            *
                          </i>
                        ) : null}
                        <br />
                        <FieldSelector
                          actionMode={props.actionMode}
                          model={item}
                          key={index}
                          obj={formik.values}
                          updateField={updateField}
                          value={formik.values[item.FieldName]}
                        />
                        {formik.errors[item.FieldName] && (
                          <div className="invalid text-sm">
                            {formik.errors[item.FieldName]}
                          </div>
                        )}
                      </Col>
                    ))}
                    <Col className="col-md-2 text-end">
                      <div class="d-grid gap-2 pt-3">
                        <button
                          className="btn btn-sm btn-secondary "
                          type="button"
                          title="Clear"
                          onClick={() => formik.resetForm()}
                        >
                          <i className="fas fa-eraser"></i> Clear
                        </button>
                      </div>
                    </Col>
                    <Col className="col-md-2 text-end">
                      <div class="d-grid gap-2 pt-3">
                        <button
                          className="btn btn-sm btn-primary "
                          title="Search"
                          onClick={() => doSearch()}
                          type="button"
                          disabled={props.isLoading}
                        >
                          <i className="fas fa-search"></i> Search
                        </button>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <hr />
                    </Col>
                    <Col xs={12}>
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Guest</th>
                            <th>Room</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reservationGeneralInfo ? (
                            <tr>
                              <td>{reservationGeneralInfo?.GuestName}</td>
                              <td>{reservationGeneralInfo?.Room}</td>
                              <td>{reservationGeneralInfo?.Status}</td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan="3" className="text-center">
                                No reservation selected
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Col>
                    <Col xs={12}>
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              {/* <th className='text-nowrap text-center'>Select</th> */}
                              <th className="text-nowrap">Folio</th>
                            </tr>
                          </thead>
                          <tbody>
                            {records &&
                              records.length > 0 &&
                              records.map((item, index) => {
                                console.log(item);
                                return (
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      setSelectedFolioInReservation(item)
                                    }
                                    className={
                                      "cursor-pointer " +
                                      (selectedFolioInReservation &&
                                      selectedFolioInReservation.GUIDReservationToFolio ===
                                        item?.GUIDReservationToFolio
                                        ? "active"
                                        : "") +
                                      " " +
                                      (selectedFolioInReservation === item
                                        ? "bg-info"
                                        : "")
                                    }
                                  >
                                    {/* <td className='text-nowrap text-center'>
                                      <input type="checkbox" />
                                    </td> */}
                                    <td>{item.Folio}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                    <Col xs={12} className="text-end mb-2">
                      <button
                        className="btn btn-primary me-2"
                        type="button"
                        disabled={selectedFolioInReservation === null}
                        onClick={() => handleSubmitToAnotherReservation()}
                      >
                        Move
                      </button>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default Transfer;
