/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Card, Col, Container, Dropdown, Form, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import API from '../../../api/api';
import { useToast } from '../../../contexts/toast';
import { dateFormater } from '../../../utils/date-formater';
import { parsedUser } from '../../../utils/GetCurrentUser';
import { USDollar } from '../../../utils/number-to-currency';
import { MasterFolioFields } from '../fields/CashierFields';
import PrintInvoice from './PrintInvoice';
import RelatedInvoice from './RelatedInvoice';
import CRUDPageGridCustomCommentCashier from '../../../pages/crud/CRUDPageGridCustomCommentCashier';
import { GetDataOfJSON } from '../../../utils/GetDataOfJSON';
import AddMasterFolio from './AddMasterFolio';
import MakeAjustment from './MakeAjustment';

const MasterFolioComponent = forwardRef(({
  UpdateMasterfolioSelectedItems,
  reservationInfo,
  // selectAllFolioDetails,
  currencySelected,
  masterFolioState,
  setMasterFolioState,
  // setSelectedItem
}, ref) => {
  const { t } = useTranslation();
  const toast = useToast();
  const fields = MasterFolioFields;
  const user = parsedUser();
  const [MasterFolioSelected, setMasterFolioSelected] = useState(null);
  const [selectData, setSelectData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [folios, setFolios] = useState([]);
  const [showAddFolios, setShowAddFolios] = useState(false);
  const [foliosDetails, setFoliosDetails] = useState([]);
  const [selectedFolioDetails, setSelectedFolioDetails] = useState([]);
  const [selectedFolio, setSelectedFolio] = useState(null);
  const [generalInfo, setGeneralInfo] = useState(null);
  const [showRelatedInvoice, setShowRelatedInvoice] = useState(false);
  const [showPrintInvoice, setShowPrintInvoice] = useState(false);
  const [selectedFolioDetail, setSelectedFolioDetail] = useState(null);
  const [showAjustment, setShowAjustment] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false);
  let totalAmountFolios = 0;
  let totalAmountFolioDetails = 0;

  useEffect(() => {
      UpdateMasterfolioSelectedItems(selectedFolioDetails);
      console.log(selectedFolioDetails);
  },[selectedFolioDetails])

  const handleMasterFolioChange = (fieldValue) => {
    setSelectedFolio(null);
    setFoliosDetails([]);
    setSelectedFolioDetails([]);
    setMasterFolioSelected(fieldValue);
    // console.log(fieldValue);
    // 
    const fullSelectedFolio = folios.filter((x) => x.GUIDReservationToFolio === fieldValue)[0];
    setMasterFolioState({...masterFolioState, selectedFolio: fullSelectedFolio});
  }

  const toggleAddFolios = () => {
    setShowAddFolios(!showAddFolios);
    if (showAddFolios) {
      search();
      if (selectedFolio) {
        searchFolioDetails(
          selectedFolio,
          reservationInfo.Room,
          reservationInfo.ReservationNumber
        );
      }
    }
  };

  const fillSelect = async () => {
    setIsLoading(true);
    
    try {
      const query = `IdUser=${user.IdUser}&token=${user.Token}`;
      const queryData = {
        Data: `@IdBusinessUnit=${user.IdBusinessUnit}, @Accion='M'`,
      }
      const request = await API.postAction(
        `api/cashier/SearchReservation?` + query,
        queryData ?? ""
      );
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setSelectData(parsedResults?.Record);

    } catch (error) {
      console.log(error);  
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fillSelect();
    return () => {
      setSelectData([]);
      setSelectedFolio(null);
      setMasterFolioState(
        {
        ...masterFolioState,
        selectedItem: null,
        selectedFolio: null,
        MasterFolioSelected: null,
        generalInfo: null
      })
    }
  }, [])

  useEffect(() => {
    if (selectData.length === 0) {
      return;
    }
    switch (MasterFolioSelected) {
      case "null":
        setFolios([]);
        break;
      default:
        search();
        break;
    }
  }, [MasterFolioSelected, currencySelected])

  const search = async () => {
    setIsLoading(true);
    setFolios([]);
    try {
      const query = `IdUser=${user.IdUser}&token=${user.Token}`;
      const queryData = {
        Data: `@IdBusinessUnit=${user.IdBusinessUnit}, @IdCurrency=${currencySelected ? currencySelected : "null"}, 
        @GUIDMasterFolio=${MasterFolioSelected ? "'" + MasterFolioSelected + "'" : "null"}, 
        @Accion='L', @RecordSource='M'`,
      };
      const request = await API.postAction(
        `api/cashier/SearchReservation?` + query,
        queryData ?? ""
      );
  
      const results = request.data[0];
      const parsedResults = JSON.parse(results.JSONData)[0];
      // console.log({Data: parsedResults})
      if (parsedResults.Error) {
        confirmAlert({
          closeOnClickOutside: true,
          message: parsedResults.Msg,
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
        return;
      }
      const record = parsedResults.Record[0];
      console.log("🚀 ~ search ~ record:", record);
      setGeneralInfo(record);
      setMasterFolioState({...masterFolioState, generalInfo: record});
      setFolios(parsedResults?.Data || []);
    } catch (error) {
      console.error(error);
      if(error instanceof Error) {
        toast({
          type: "error",
          message: error.message,
        })
        return;
      }
      toast({
        type: "error",
        message: "An error occurred",
      })
      
    } finally {
      setIsLoading(false);
    }
  };

  const searchFolioDetails = async (folio) => {
    setIsLoading(true);
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;

      let queryData = {
        Data: `@GUIDReservationToFolio =${folio.GUIDReservationToFolio
            ? "'" + folio.GUIDReservationToFolio + "'"
            : "null"
          }, @IdBusinessUnit=${user.IdBusinessUnit}, @IdCurrency=${currencySelected ? currencySelected : "null"
          }, @RecordSource='M', @GUIDMasterFolio=${MasterFolioSelected ? "'" + MasterFolioSelected + "'" : "null"}`,
      };

      let request = await API.postAction(
        `api/cashier/FolioDetails?` + query,
        queryData ?? ""
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      console.log("🚀 ~ searchFolioDetails ~ parsedResults:", parsedResults);
      setFoliosDetails(parsedResults?.Data || []);
      if (parsedResults.Error) {
        confirmAlert({
          closeOnClickOutside: true,
          message: parsedResults.Msg,
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
        //toast({ message: parsedResults.Msg, type: 'error' });
        return;
      }

      setFoliosDetails(parsedResults?.Data || []);
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        toast({
          type: "error",
          message: error.message,
        });
        return;
      }
      toast({
        type: "error",
        message: "An error occurred",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFolioChange = (folio) => {
    console.log("🚀 ~ handleFolioChange ~ folios:", folios)
    // setSelectedItem(null);
    setSelectedFolioDetails([]);
    searchFolioDetails(folio);
    setSelectedFolio(folio);
    // setGeneralInfo(folio);
    // console.log(folios);
    const fullSelectedFolio = folios.filter((x) => x.GUIDReservationToFolio === folio.GUIDReservationToFolio)[0];
    setMasterFolioState({...masterFolioState, MasterFolioSelected: MasterFolioSelected, selectedFolio: fullSelectedFolio});
  }

  const selectAllFolioDetails = () => {
    const allowedToBePaid = foliosDetails.filter(
      (x) => x.AllowPayment === true
    );
    setSelectedFolioDetails(allowedToBePaid);
  };

  const handleCheckboxChange = (event, id) => {
    // console.log(event.target.checked, id);
    const exist = selectedFolioDetails.filter(
      (rowId) => rowId.GUIDReservationToFolioDetail === id.GUIDReservationToFolioDetail
    )[0];
    // console.log(id, exist, selectedFolioDetails);
    if (!exist) {
      setSelectedFolioDetails([...selectedFolioDetails, id]);
    } else {
      setSelectedFolioDetails(
        selectedFolioDetails.filter(
          (rowId) => rowId.GUIDReservationToFolioDetail !== id.GUIDReservationToFolioDetail
        )
      );
    }
  };
  
  const toggleRelatedInvoice = (obj) => {
    setSelectedFolioDetail(obj);
    setShowRelatedInvoice(true);
  };
  const closeRelatedInvoice = () => {
    setShowRelatedInvoice(false);
  };

  
  const togglePrintInvoice = (obj) => {
    setShowPrintInvoice(!showPrintInvoice);
    setSelectedFolioDetail(obj);
  };

  const nullInvoice = async (obj) => {
    confirmAlert({
      message: "Are you sure you want to null this invoice?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            console.log(obj);

            let query = `token=${user.Token}&IdUser=${user.IdUser}`;
            let queryData = {
              Data: `@GUIDBillingDocument ='${obj.GUIDBillingDocument}'`,
            };

            await API.postAction("api/cashier/nullInvoice?" + query, queryData);
            console.log(reservationInfo);
            await search();
            if (selectedFolio) {
              await searchFolioDetails(selectedFolio);
            }
          },
        },
        { label: "No" },
      ],
    });
  };

  const toggleAjustment = (item) => {
    setSelectedFolioDetail(item);
    setShowAjustment(!showAjustment);

    if (showAjustment) {
      search();
      if (selectedFolio) {
        searchFolioDetails(selectedFolio);
      }
    }
  };

  const reverse = async (obj) => {
    confirmAlert({
      message: "Are you sure you want to reverse this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            // console.log(obj);
            // debugger;
            let query = `token=${user.Token}&IdUser=${user.IdUser}`;
            let queryData = {
              Data: `@IdReservationToFolioDetail=${obj.IdReservationToFolioDetail}`,
            };

            let requestAPI = await API.postAction(
              "api/cashier/reverse?" + query,
              queryData
            );

            let parsedResults = GetDataOfJSON(
              requestAPI,
              "data",
              0,
              "JSONData",
              0
            );

            if (parsedResults.Error) {
              toast({
                message: parsedResults.Msg,
                type: "error",
                height: "auto",
                width: "auto",
              });
            } else {
              await search();
              if (selectedFolio) {
                await searchFolioDetails(selectedFolio);
              }
            }
          },
        },
        { label: "No" },
      ],
    });
  };

  useImperativeHandle(ref, () => ({
    MasterFolioSearch() {
      search();
      searchFolioDetails(selectedFolio);
    },
  }));

  return (
    <>
      {showAddFolios && (
        <AddMasterFolio
          show={true}
          toggle={toggleAddFolios}
          GUIDMasterFolio={MasterFolioSelected}
        />
      )}
      {showPrintInvoice && (
        <PrintInvoice
          show={true}
          toggle={togglePrintInvoice}
          selectedFolioDetails={selectedFolioDetails}
        />
      )}
       {showRelatedInvoice && (
        <RelatedInvoice
          show={showRelatedInvoice}
          close={closeRelatedInvoice}
          selectedFolioDetails={selectedFolioDetails}
        />
      )}
      {showAjustment && (
        <MakeAjustment
          show={showAjustment}
          toggle={toggleAjustment}
          parent={selectedFolio}
          selectedFolioDetail={selectedFolioDetail}
          IdBillingService={selectedFolioDetail?.IdBillingService}
        />
      )}
    <Container fluid>
      <Row className="border mb-4">
        <Col xs={6} className="my-4">
          <div className='mx-4'>
            <Form.Group className="d-flex">
              <Form.Label className="mt-2 d-block" style={{width: '100px'}}>Master Folio</Form.Label>
              <Form.Select onChange={(e) => handleMasterFolioChange(e.target.value)} disabled={isLoading}>
                <option value="null">Select...</option>
                {selectData?.length > 0 && selectData?.map((item, index) => {
                  return <option key={index} value={item.GUIDMasterFolio}>{item.Description}</option>
                }
                )}
              </Form.Select>
            </Form.Group>
          </div>
        </Col>
        <Col xs={6}>
        <div className='w-100 d-flex justify-content-end align-items-center h-100'>
            <button className='btn btn-outline-danger'>
              <i className='fa fa-times me-2'></i>
              Close Master Folio
            </button>
        </div>
        </Col>
      </Row>

      <Row>
        {/* COMMENTS and FOLIOS SECTION*/}
        <Col md={4} xs={12}>
          {/* FOLIOS */}
          <Card className="mb-4">
            <Card.Header>
              <Card.Title className="me-2 d-inline">Folios</Card.Title>
              <button
                className="btn btn-sm btn-secondary"
                type="button"
                onClick={() => toggleAddFolios()}
                disabled={MasterFolioSelected === null || isLoading}
              >
                <i className="fa-solid fa-plus"></i>
              </button>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          {/* <th className='text-nowrap text-center'>Select</th> */}
                          <th className="text-nowrap">{t("Description")}</th>
                          <th className="text-nowrap text-end">
                            {t("Total Amount")}{" "}
                            {generalInfo && generalInfo.CurrencyCode}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {folios.length === 0 && (
                          <tr>
                            <td colSpan="2">No records found</td>
                          </tr>
                        )}
                        {folios.length > 0 &&
                          folios.map((item, index) => {
                            totalAmountFolios += item.TotalAmount * 1;
                            // console.log(item);
                            return (
                              <tr
                                key={index}
                                onClick={() => handleFolioChange(item)}
                                style={{ cursor: "pointer" }}
                                className={`${item?.AutoInvoiceEnabled && "fw-bold"
                                  }
                                ${selectedFolio &&
                                  selectedFolio.GUIDReservationToFolio ===
                                  item?.GUIDReservationToFolio &&
                                  "active"
                                  }
                                ${selectedFolio === item
                                    ? "bg-info"
                                    : ""
                                  }`}
                              >
                                <td>{item.FolioType}</td>
                                <td className="text-end">
                                  {USDollar(item.TotalAmount)}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xs={12} className="text-end">
                  {/* {console.log("folios", folios)} */}
                  <span>
                    {t("Grand Total")} {generalInfo && generalInfo.CurrencyCode}:{" "}
                    {USDollar(totalAmountFolios)}
                  </span>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
          {/* COmments */}
          {reservationInfo && (
            <Row className="border pb-5 rounded-2">
              <div>
                <CRUDPageGridCustomCommentCashier
                  getEndpoint="api/Reservations/ReservationsCommentHistoryRecords"
                  modelEndpoint="api/Reservations/ReservationsCommentHistoryModel"
                  addEditEndpoint="api/Reservations/ReservationsCommentHistoryAddEdit"
                  deleteEndpoint="api/Reservations/ReservationsCommentHistoryDelete"
                  detailsEndpoint="api/Reservations/ReservationsCommentHistoryDetails"
                  headerEndpoint="api/Reservations/ReservationsCommentHistoryHeader"
                  ParentGUID={reservationInfo.GUIDReservation}
                  Parent={reservationInfo}
                  ParentField={"GUIDReservation"}
                  ParentFieldValue={reservationInfo?.GUIDReservation}
                  ParentFieldType={"string"}
                  page={{
                    PageIndex: 2,
                    PageTitle: "Comments",
                    PageIcon: "fa-solid fa-file-lines",
                    MultiRecord: true,
                    Active: true,
                  }}
                />
              </div>
            </Row>
          )}
        </Col>

        <Col md={8} xs={12}>
          {/* FOLIOS DETAILS */}
          <Card className="h-100">
            <Card.Header>
              <Card.Title className="d-flex justify-content-between align-items-center m-0">
                <span>
                  {t("Folio Detail")}
                </span>
                <div className="d-flex gap-4">
                  <button
                    className="btn btn-primary"
                    onClick={selectAllFolioDetails}
                  >
                    {t("Select All")}
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      setSelectedFolioDetails([])
                    }}
                  >
                    {t("Unselect All")}
                  </button>
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => {
                      setShowCancelled(!showCancelled);
                    }}
                  >
                    {showCancelled ? t("Hide Cancelled") : t("Show Cancelled")}
                  </button>
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row className="h-100">
                <Col className="table-responsive ">
                  <table className="table table-bordered table-striped">
                    <thead className="table-secondary">
                      <tr>
                        <th
                          className="text-nowrap text-center align-content-center"
                          rowSpan={2}
                        >
                          Select
                        </th>
                        <th
                          className="text-nowrap align-content-center"
                          rowSpan={2}
                        >
                          Date
                        </th>
                        <th
                          className="text-nowrap text-center align-content-center"
                          rowSpan={2}
                        >
                          Transaction Type
                        </th>
                        <th
                          className="text-nowrap text-center align-content-center"
                          rowSpan={2}
                        >
                          Billing Service
                        </th>
                        <th
                          className="text-nowrap text-center align-content-center"
                          rowSpan={2}
                        >
                          References
                        </th>
                        <th
                          className="text-nowrap text-center align-content-center"
                          colSpan={2}
                        >
                          Amount {generalInfo && generalInfo.CurrencyCode}
                        </th>
                        <th
                          className="text-nowrap text-center align-content-center"
                          rowSpan={2}
                        >
                          Billing Status
                        </th>
                        <th
                          className="text-nowrap text-center align-content-center"
                          rowSpan={2}
                        ></th>
                      </tr>
                      <tr>
                        <th className="text-nowrap text-center">Total</th>
                        <th className="text-nowrap text-center">
                          Pending
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {foliosDetails.length > 0 ?
                        foliosDetails
                          .filter((x) => showCancelled || x.Active) //TODO FIX.
                          .map((item, index) => {
                            // console.log({AmountFolioDetails: item.Amount, totals: totalAmountFolioDetails});
                            if (item.Active) {
                              totalAmountFolioDetails += item.Amount;
                            }
                            let exist =
                              selectedFolioDetails &&
                              selectedFolioDetails.filter(
                                (x) =>
                                  x.TransactionDate ===
                                  item.TransactionDate
                              )[0];
                            // console.log(item);
                            return (
                              <tr
                                key={index}
                                style={{
                                  height: "1rem",
                                  textDecoration:
                                    item.Active === false
                                      ? "line-through"
                                      : "",
                                }}
                              >
                                <td className="text-nowrap text-center align-content-center">
                                  <input
                                    type="checkbox"
                                    checked={exist}
                                    onClick={(e) => {
                                      console.log(
                                        e.target.checked,
                                        item
                                      );
                                      handleCheckboxChange(e, item);
                                    }}
                                  />
                                </td>
                                <td className="align-content-center">
                                  {dateFormater(item.TransactionDate)}
                                </td>
                                <td className="text-center align-content-center">
                                  <span
                                    className="badge w-100"
                                    style={{
                                      background:
                                        item.BackgroundColorDescrip ||
                                        "gray",
                                      color:
                                        item.TextColorDescrip || "white",
                                    }}
                                  >
                                    {item.Description}
                                  </span>
                                </td>
                                <td className="align-content-center">
                                  {item.BillingService || "-"}
                                </td>
                                <td className="align-content-center">
                                  {item.Reference}
                                </td>
                                <td className="text-end align-content-center">
                                  {USDollar(item.Amount)}
                                </td>
                                <td className="text-end align-content-center">
                                  {item.PendingAmount > 0 &&
                                    USDollar(item.PendingAmount)}
                                </td>
                                <td className="text-center align-content-center">
                                  <span
                                    className="badge w-100"
                                    style={{
                                      background:
                                        item.BackgroundColorStatus,
                                      color: item.TextColorStatus,
                                    }}
                                  >
                                    {item.Status}
                                  </span>
                                </td>
                                <td className="text-end align-content-center py-0">
                                  {item.Active && (
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="button"
                                        id="dropdown-basic"
                                        size="sm"
                                      >
                                        <i className="fa fa-ellipsis-v" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        {item.AllowReversal &&
                                          moment(user.OperationDate).isSame(
                                            moment(),
                                            "day"
                                          ) ? (
                                          <Dropdown.Item
                                            onClick={() => {
                                              reverse(item);
                                            }}
                                          >
                                            Reverse
                                          </Dropdown.Item>
                                        ) : null}

                                        {item.AllowAdjustment &&
                                          item.Status.toLowerCase() !==
                                          "paid" && (
                                            <Dropdown.Item
                                              onClick={() => {
                                                //      toggleOpen("D");
                                                toggleAjustment(item);
                                              }}
                                            >
                                              Adjust
                                            </Dropdown.Item>
                                          )}

                                        {/* {item.AllowTransfer &&
                                          item.PendingAmount ===
                                          item.Amount ? (
                                          <Dropdown.Item
                                            onClick={() => {
                                              setSelectedFolioDetails([
                                                item,
                                              ]);
                                              toggleTransfer(); //(item);
                                            }}
                                          >
                                            Transfer
                                          </Dropdown.Item>
                                        ) : null} */}

                                        {item.TransactionTypeCode ===
                                          "P" && (
                                            <>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  togglePrintInvoice(item);
                                                }}
                                              >
                                                Print Invoice
                                              </Dropdown.Item>
                                            </>
                                          )}
                                        {(item.StatusCode === "I" ||
                                          item.StatusCode === "P") &&
                                          item.Active && (
                                            <>
                                              {item.Status?.toLowerCase() !==
                                                "partial payment" && (
                                                  <Dropdown.Divider />
                                                )}
                                              <Dropdown.Item
                                                onClick={() => {
                                                  toggleRelatedInvoice(
                                                    item
                                                  );
                                                }}
                                              >
                                                Related Invoice
                                              </Dropdown.Item>
                                            </>
                                          )}
                                        {item.TransactionTypeCode ===
                                          "P" && !item.Active ? (
                                          <Dropdown.Item
                                            onClick={() => {
                                              nullInvoice(item);
                                            }}
                                          >
                                            Null Invoice
                                          </Dropdown.Item>
                                        ) : null}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        : (<tr>
                          <td colSpan="9" className="text-center bg-light">{t("No records found on folio selected")}</td>
                        </tr>)
                      }
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col md={12} className="text-end">
                  <span>
                    {t("Grand Total")} {generalInfo && generalInfo.CurrencyCode}:{" "}
                    {USDollar(totalAmountFolioDetails)}
                  </span>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  )
},[])

export default MasterFolioComponent