/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Card, Col } from 'react-bootstrap';
import { USDollar } from '../../../utils/number-to-currency';
import './CustomStyles.css';

const DepositAsMethodPayment = ({
  depositBaseCurrency,
  DepositAvailable = 0,
  setAppliedDeposit,
  baseCurrency
}) => {
  const [Amount, setAmount] = useState(0);

  useEffect(() => {
    setAppliedDeposit(+Amount);
  }, [Amount]);

  return (
    <div className={"mb-5 px-1 py-1 deposit-box rounded-2"}>
      <Card>
        <Card.Header as='h5'>Deposit available</Card.Header>
        <Card.Body>
          <Col xs={12} className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="text-nowrap">Concept</th>
                  <th className="text-nowrap">
                    Amount{" "}
                    {/* {depositBaseCurrency && depositBaseCurrency.Currency} */}
                  </th>
                  <th className="text-nowrap">Amount available {baseCurrency?.Currency}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-no-wrap">
                    Deposit
                    <br />
                    <span className="text-muted">
                      1 {depositBaseCurrency?.ISOCode} ={" "}
                      {USDollar(depositBaseCurrency?.RateValue)}
                    </span>
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={Amount}
                      onChange={(e) => setAmount(e.target.value)}
                      // On blur validate the amount is not greater than the available
                      onBlur={() => {
                        if (Amount > DepositAvailable) {
                          setAmount(DepositAvailable);
                        }
                      }}
                      max={DepositAvailable}
                      min={0}
                    ></input>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={USDollar(DepositAvailable * depositBaseCurrency?.RateValue)}
                      disabled
                    ></input>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Card.Body>
      </Card>
    </div>
  )
}

export default DepositAsMethodPayment
