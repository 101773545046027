import React, { useEffect, useState } from "react";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useToast } from "../../contexts/toast";

export default function ChangeOrganization(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const user = parsedUser();
  const { t } = useTranslation();
  const toast = useToast();
  const requestOrganizations = async () => {
    try {
      setIsLoading(true);

      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;
      let requestAPI = await API.postAction(
        `api/account/ChangeBusinessUnit?${queryString}`,
        { Data: null }
      );
      if (requestAPI.data) {
        console.log(requestAPI.data);
        let data = requestAPI.data[0].JSONData;
        console.log(data);
        let parsed = JSON.parse(data);
        console.log(parsed);
        setRecords(parsed[0].Records);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const selectItem = async (item) => {
    try {
      const userToSend = user;
      userToSend.IdBusinessUnit = item.IdBusinessUnit;
      userToSend.NameBusinessUnit = item.BusinessUnit;
      const requestAPI = await API.postAction(
        `api/account/GeneratetokenWithId`,
        userToSend
      );
  
      localStorage.setItem("LogedUser", requestAPI.data.response);
      window.location.reload();
      props.toggle();
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        toast({
          type: "error",
          message: error.message,
          width: 'auto',
          height: 'auto'
        })
        return;
      }
      toast({
        type: "error",
        message: 'Something went wrong while changing the organization',
        width: 'auto',
        height: 'auto'
      })
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    requestOrganizations();
  }, []);
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
      size="sm"
    >
      <Modal.Header closeButton>
        <div className="w-100 d-flex">
          <div className="d-flex my-2">
            <h4>{t("Business Units")}</h4>
          </div>
          <div>
            {isLoading && (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {records.length > 0 && (
            <Row className=" pb-4 mb-4">
              {records.map((item, index) => {
                console.log(item);
                return (
                  <Col xs={12} key={index} className="border-bottom mb-4">
                    <button
                      className="btn btn-block btn-light mb-2"
                      onClick={() => selectItem(item)}
                    >
                      {item.BusinessUnit}
                    </button>
                  </Col>
                );
              })}
            </Row>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}
