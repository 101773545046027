import { t } from "i18next";

const RatePeriodFields = [
  {
    IdAppForm: 64,
    Id: 6400003,
    FieldName: "Id",
    FieldType: "bigint",
    FieldTitle: t("Id"),
    Position: 3,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "Code",
    FieldType: "varchar",
    FieldTitle: t("Code"),
    Position: 4,
    cssClasss: "col-12 col-md-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: true,
  },
  {
    IdAppForm: 64,
    Id: 6400004,
    FieldName: "RateType",
    FieldType: "int",
    FieldTitle: t("Rate Type"),
    Position: 4,
    cssClasss: "col-12 col-md-3",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "C",
    OptionValues: "[{\"id\": \"C\",\"text\": \"Contract\"},{\"id\": \"S\",\"text\": \"Special\"}]",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400008,
    FieldName: "DateStart",
    FieldType: "datetime",
    FieldTitle: t("Start date"),
    Position: 8,
    cssClasss: "col-12 col-md-3",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400009,
    FieldName: "DateEnd",
    FieldType: "datetime",
    FieldTitle: t("End date"),
    Position: 9,
    cssClasss: "col-12 col-md-3",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,

    minValueDependOf: "DateStart",
  },

  {
    IdAppForm: 64,
    Id: 6400005,
    FieldName: "Description",
    FieldType: "varchar",
    FieldTitle: t("Rate Description"),
    Position: 5,
    cssClasss: "col-12 col-md-6 ",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },

  {
    IdAppForm: 62,
    Id: 6200002,
    FieldName: "IdSpecialCode",
    FieldType: "bigint",
    FieldTitle: t("Rate Code"),
    Position: 2,
    cssClasss: "col-12 col-md-6",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:
      '{"HashID":"78b1523e6fa39d70f4a0333d0d13722d00025027ceffe056d5677ec1d8b67331"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },

  {
    IdAppForm: 64,
    Id: 6400009,
    FieldName: "FreeNights",
    FieldType: "bit",
    FieldTitle: t("Free Nights"),
    Position: 9,
    cssClasss: "col-12 col-md-3",
    HtmlType: "radio",
    PlaceHolder: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    DefaultValue: '0',
    OptionValues: `[{"id": 1,"text": "Yes"},{"id": 0,"text": 
      "No"
    }]`,
  },


  {
    IdAppForm: 64,
    Id: 6400009,
    FieldName: "FreeNightsCount",
    FieldType: "int",
    FieldTitle: t("Amount of Free Nights"),
    Position: 9,
    cssClasss: "col-12 col-md-3 col-lg-2",
    HtmlType: "number",
    PlaceHolder: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400009,
    FieldName: "FreeNightsMinStay",
    FieldType: "int",
    FieldTitle: t("Min. Stay"),
    Position: 9,
    cssClasss: "col-12 col-md-3 col-lg-2",
    HtmlType: "number",
    PlaceHolder: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },

  {
    IdAppForm: 64,
    Id: 6400009,
    FieldName: "FreeNightsMaxStay",
    FieldType: "int",
    FieldTitle: t("Max. Stay"),
    Position: 9,
    cssClasss: "col-12 col-md-3 col-lg-2",
    HtmlType: "number",
    PlaceHolder: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },

  {
    IdAppForm: 64,
    Id: 6400009,
    FieldName: "FreeNightsAvgRate",
    FieldType: "bit",
    FieldTitle: t("Avg. Rate"),
    Position: 9,
    cssClasss: "col-12 col-md-3",
    HtmlType: "radio",
    PlaceHolder: "",
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
    DefaultValue: '0',
    OptionValues: `[{"id": 1,"text": "Yes"},{"id": 0,"text": 
      "No"
    }]`,
  },

  {
    IdAppForm: 64,
    Id: 6400012,
    FieldName: "Comments",
    FieldType: "varchar",
    FieldTitle: t("Comments"),
    Position: 12,
    cssClasss: "col-12 ",
    HtmlType: "textarea",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400013,
    FieldName: "Active",
    FieldType: "bit",
    FieldTitle: t("Active"),
    Position: 13,
    cssClasss: "col-12 col-md-4",
    HtmlType: "radio",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: `[{"id": 0,"text": "No"},{"id": 1,"text": "Yes"}]`,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
  },
  {
    IdAppForm: 64,
    Id: 6400021,
    FieldName: "GUID",
    FieldType: "uniqueidentifier",
    FieldTitle: t("GUID"),
    Position: 21,
    cssClasss: "col-12 col-md-4",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: false,
  },
];

export default RatePeriodFields;
