import React, { useEffect, useState } from "react";
import API from "../../../api/api";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const BumpOutTab = ({ GUIDReservation }) => {
  const user = parsedUser();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState({});

  const bindDataRemote = async (filter) => {
    // First start loading the model
    setIsLoading(true);

    //First load records
    try {
      const query = `IdUser=${user.IdUser}&token=${user.Token}`;
      const queryData = {
        Data: `@GUIDReservation='${GUIDReservation}', @Accion ='L'`,
      };
      const request = await API.postAction(
        `api/reservations/reservationBumpOut?` + query,
        queryData ?? ""
      );
      const results = request.data[0];
      const parsedResults = JSON.parse(results.JSONData)[0];
      console.log("🚀 ~ bindDataRemote ~ parsedResults:", parsedResults);
      setRecords(parsedResults.JSONData[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bindDataRemote();
  }, []);

  return (
    <>
      <Container fluid className="overflow-auto">
        <Row>
          <Col xs={6}>
            <label className="me-2 mt-2">{t("Bump Out Hotel")}</label>
            <br />
            <Form.Control type="text" disabled value={records?.BumpOutHotel} />
          </Col>

          <Col xs={6}>
            <label className="me-2 mt-2">{t("Bump Out Room Reference")}</label>
            <br />
            <Form.Control type="text" disabled value={records?.BumpOutRoom} />
          </Col>
          <Col xs={6}>
            <label className="me-2 mt-2">{t("Bump Out Rate Reference")}</label>
            <br />
            <Form.Control type="text" disabled value={records?.BumpOutRate} />
          </Col>
          <Col xs={6}>
            <label className="me-2 mt-2">{t("Bump Out Date")}</label>
            <br />
            <Form.Control type="text" disabled value={records?.BumpOutDate} />
          </Col>
          <Col xs={6}>
            <label className="me-2 mt-2">{t("Returning")}</label>
            <br />
            <Form.Control
              type="text"
              disabled
              value={records?.Returning ? t("Yes") : t("No")}
            />
          </Col>
          <Col xs={6}>
            <label className="me-2 mt-2">{t("Returning Date")}</label>
            <br />
            <Form.Control
              type="text"
              disabled
              value={records?.BumpOutReturningDate}
            />
          </Col>
          <Col xs={12}>
            <label className="me-2 mt-2">{t("Comment")}</label>
            <br />
            <textarea
              className="form-control"
              disabled
              value={records?.Comments}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BumpOutTab;
