/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import { parsedUser } from "../../../utils/GetCurrentUser";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import FieldSelector from "../../customComponents/FieldSelector";

import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";

const AddMasterFolio = ({
  GUIDMasterFolio,
  show,
  toggle,
  modalSize,

}) => {
  const user = parsedUser();
  const toast = useToast();

  const [validationsSchema, setValidationSchema] = useState(null);
  const fields = [
    {
      FieldName: "IdFolioType",
      FieldType: "varchar",
      FieldTitle: "Folio Type",
      cssClasss: "col-12 ",
      HtmlType: "select",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource:
        '{"HashID":"6ea9a3d43f4d7eac355b9cf5b7bd3c25693eacf3f7cf28b1d2cfb0dc03161c1a"}',
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "Description",
      FieldType: "varchar",
      FieldTitle: "Description",
      cssClasss: "col-12 ",
      HtmlType: "text",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource: "",
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
  ];

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    console.log(GUIDMasterFolio)
  },[])

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const handleSubmit = async (obj, event) => {
    console.log("object to save", obj);

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;

      let queryData = {
        Data: `@IdFolioType=${
          obj.IdFolioType ? obj.IdFolioType : "null"
        }, @GUIDdMasterFolio='${GUIDMasterFolio}', @RecordSource='M',
        @Description='${obj.Description}'`,
      };
      let request = await API.postAction(
        `api/cashier/AddFolio?` + query,
        queryData ?? ""
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          toast({
            type: "error",
            message: "This folio already exist.",
          });
          return;
        }
      }
      toast({
        type: "success",
        message: "Record saved successfully",
      });

      toggle();
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {},
    validateOnChange: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    prepareFormikObject();
  }, []);
  
  return (
    <Modal
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
      size={modalSize ?? "sm"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Folio</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container fluid className="overflow-auto table-responsive">
          <Row>
            {fields &&
              fields
                .filter(
                  (x) =>
                    x.FieldTitle !== "Audit Information" &&
                    x.FieldName !== "Id" &&
                    x.FieldName !== "IdCustomer" &&
                    x.FieldName !== "DateAdd" &&
                    x.FieldName !== "File" &&
                    x.FieldName !== "DateMod"
                )
                .map((item, index) => {
                  return (
                    <Col
                      className={
                        item.cssClasss + (!item.Visible ? " d-none" : "")
                      }
                      key={`col-${item.FieldName}-${index}`}
                    >
                      <label className="me-2 mt-2">
                        {item.FieldTitle}
                        {item.Required ? (
                          <i className="required-asterisk ms-1 text-danger">
                            *
                          </i>
                        ) : null}
                      </label>
                      <br />

                      <FieldSelector
                        actionMode={""}
                        model={item}
                        obj={formik.values}
                        key={`field-${item.FieldName}`}
                        updateField={updateField}
                        value={formik.values[item.FieldName]}
                      ></FieldSelector>
                      {formik.errors[item.FieldName] ? (
                        <div className="invalid text-sm my-1">
                          {formik.errors[item.FieldName]}
                        </div>
                      ) : null}
                    </Col>
                  );
                })}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gx-3 align-items-center justify-content-end">
          {Object.values(formik.errors).length > 0 && (
            <span className="invalid me-2">
              Please check the forms for errors
            </span>
          )}
          <button className="btn me-2" type="button" onClick={toggle}>Close</button>
          <button
            className="btn btn-primary me-2"
            type="button"
            onClick={() => [formik.submitForm()]}
          >Save</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMasterFolio;
