import { useFormik } from "formik";
import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Col, Form } from "react-bootstrap";
import * as yup from "yup";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import FieldSelector from "../../customComponents/FieldSelector";
import { CashierFilterFields } from "../fields/CashierFields";
import { useTranslation } from "react-i18next";

// I neeed to forwardRef to use the formik.submitForm() method in the parent component

const CashierFilter = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const fields = CashierFilterFields;
  const [validationsSchema, setValidationSchema] = useState(null);

  const handleSubmit = async (obj) => {
    props.handleFilter(obj);
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};
    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;
        initialObject[item.FieldName] = item.value;
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const updateField = (fieldName, fieldValue) => {
    if (fieldName === "Room") {
      formik.setFieldValue("ReservationNumber", null);
    } else {
      formik.setFieldValue("Room", null);
    }
    formik.setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    prepareFormikObject();
  }, []);

  useEffect(() => {
    if (props.reservationInfo) {
      console.log(props);
      if (props.reservationInfo.ReservationNumber) {
        updateField(
          "ReservationNumber",
          props.reservationInfo.ReservationNumber
        );
      } else {
        updateField("Room", props.reservationInfo.Room);
      }
    }
  }, [props.reservationInfo]);

  useImperativeHandle(ref, () => ({
    submit() {
      formik.handleSubmit();
    },
  }));

  return (
    <Form onSubmit={formik.handleSubmit} className="my-0">
      <Col className="d-flex align-items-center my-0 px-4">
        {fields.map((item, index) => (
          <div key={index} className="d-flex align-items-center mx-2">
            <label className="me-2 fw-bold">{item.FieldTitle}</label>
            {item.Required ? (
              <i className="required-asterisk ms-1 text-danger">*</i>
            ) : null}
            <FieldSelector
              actionMode={props.actionMode}
              model={item}
              key={index}
              obj={formik.values}
              updateField={updateField}
              value={formik.values[item.FieldName]}
            />
            {formik.errors[item.FieldName] && (
              <div className="invalid text-sm">
                {formik.errors[item.FieldName]}
              </div>
            )}
          </div>
        ))}
        <div className="d-flex gap-2 align-items-center m-0 py-2">
          <button
            className="btn btn-sm btn-primary "
            title="Search"
            type="submit"
            disabled={props.isLoading}
          >
            <i className="fas fa-search"></i> {t("Search")}
          </button>
          <button
            className="btn btn-sm btn-secondary "
            type="button"
            title="Clear"
            onClick={() => formik.resetForm()}
          >
            <i className="fas fa-eraser"></i> {t("Clear")}
          </button>
        </div>
      </Col>
    </Form>
  );
});

export default CashierFilter;
