import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";
import { parsedUser } from "../../../utils/GetCurrentUser";
import TravelingWithAdd from "./TravelingWithAdd";

const TravelingWith = (props) => {
  const { t } = useTranslation();
  const user = parsedUser();
  const toast = useToast();

  const [records, setRecords] = useState([]);
  const [recordSelected, setRecordSelected] = useState(null);
  const [recordsSearch, setRecordsSearch] = useState([]);
  const [isGroupReservation, setIsGroupReservation] = useState(false);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => {
    setShowAddEdit(!showAddEdit);
    if (!showAddEdit) {
      setRecordsSearch([]);
    }
  };


  const bindDataSearch = async (obj) => {
    //First load records
    setIsLoading(true);
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&Search=${obj.Search}`;
      let prefix = "Reservations";
      let request = await API.getAction(
        `api/${prefix}/TravelingWithSearch`,
        query
      );
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      console.log(parsedResults);
      let recordResult = parsedResults.JSONData || [];

      setRecordsSearch(recordResult);
    } catch (error) {
      toast({
        type: "error",
        message: t("An error occurred while trying to search"),

      })
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const bindDataRemote = useCallback(
    async (cleanFilter) => {
      //First load records
      try {
        setIsLoading(true);
        let query = `IdUser=${user.IdUser}&token=${user.Token}&ParentGUID=${props.selectedReservation.GUID}`;
        let prefix = "Reservations";
        let request = await API.getAction(
          `api/${prefix}/TravelingWithRecords`,
          query
        );
        let results = request.data[0];
        let parsedResults = JSON.parse(results.JSONData)[0];
        let recordResult =
          (Array.isArray(parsedResults.JSONData)
            ? parsedResults.JSONData
            : [parsedResults.JSONData]) || [];
        console.log("🚀 ~ recordResult:", recordResult);
        if (recordResult[0].Type === "G") {
          setIsGroupReservation(true);
        } else {
          setIsGroupReservation(false);
        }

        setRecords(recordResult);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [props.selectedReservation]
  );

  const saveRelationship = async () => {
    let query = `IdUser=${user.IdUser}&token=${user.Token}&GUID=${props.selectedReservation.GUID}&GUIDParent=${recordsSearch[0].GUID}`;
    let prefix = "Reservations";


    var request = await API.getAction(`api/${prefix}/TravelingWithAdd`, query);
    console.log(request);
    let result = request.data[0].JSONData;
    let parsedResult = JSON.parse(result);
    console.log("result", parsedResult[0]);
    if (parsedResult[0].Error) {
      confirmAlert({
        closeOnClickOutside: false,
        message: t(parsedResult[0].Msg),
        buttons: [
          {
            label: t("Ok"),
            onClick: async () => {
              console.log("yes")
            }
          }]
      }
      );
      //    alert();
    } else {

      setRecordsSearch([]);
      await bindDataRemote();
      toggle();
    }



  };

  //Delete the record
  const deleteConfirm = async () => {
    console.log("🚀 ~ deleteConfirm ~ recordSelected:", recordSelected)
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              let query = `IdUser=${user.IdUser}&token=${user.Token}&GUID=${recordSelected.GUID}`;
              await API.getAction(
                `api/reservations/TravelingWithDelete`,
                query
              );
              await bindDataRemote();
              setRecordSelected(null);
              toast({
                type: "success",
                message: t("Record deleted successfully"),
              });
            } catch (error) {
              toast({
                type: "error",
                message: t("An error occurred while trying to delete"),
              });
              console.error(error);
            }
          },
        },
        {
          label: t("No"),
        },
      ],
    });
  };


  const reservationColor = (item) => {
    return {
      backgroundColor: item.ReservationStatusBackgroundColor || "gray",
      color: item.ReservationStatusTextColor,
    };
  };


  useEffect(() => {
    if (props.selectedReservation) {
      bindDataRemote();
    }
  }, [props.selectedReservation]);

  return (
    <>
      {showAddEdit &&
        <TravelingWithAdd
          showAddEdit={showAddEdit}
          toggle={toggle}
          bindDataSearch={(obj) => bindDataSearch(obj)}
          recordsSearch={recordsSearch}
          saveRelationship={saveRelationship}
          isLoading={isLoading}
        />}
      <Row className="mx-0">
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Row className="mt-4 h-100">
                <Col md={11}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>{t("Reservation Number")}</th>
                        <th>{t("Room")}</th>
                        <th>{t("Status")}</th>
                        <th>{t("Name")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.length > 0 ? (
                        records
                          .filter((x) => x !== null)
                          .map((item, index) => {
                            return (
                              <tr
                                key={index}
                                className={`clickable ${recordSelected && item.GUID === recordSelected?.GUID
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={() => {
                                  setRecordSelected(item);
                                }}
                              >
                                <td>{item.ReservationNumber}</td>
                                <td>{item.Room}</td>
                                <td>
                                  <span
                                    className="badge w-100"
                                    style={reservationColor(item)}
                                  >
                                    {item.Status}
                                  </span>
                                </td>
                                <td>{`${item.FirstName ?? ""} ${item.LastName ?? ""}`}</td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td className="border-1 text-center" colSpan={4}>
                            {t("No Records Found")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
                <Col md={1}>
                  <div className="d-flex flex-column gap-2 align-content-center align-items-baseline">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() => {
                        toggle();
                      }}
                      type="button"
                      disabled={props.actionMode === "VIEW" || isLoading}
                    >
                      <i className="fa-solid fa-plus" />
                    </button>
                    {recordSelected && !isGroupReservation ? (
                      <button
                        className="btn btn-outline-secondary"
                        onClick={() => deleteConfirm()}
                        type="button"
                        disabled={props.actionMode === "VIEW" || isLoading}
                      >
                        <i className="fa-solid fa-trash" />
                      </button>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TravelingWith;
