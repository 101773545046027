import "devextreme/data/odata/store";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Container, Dropdown } from "react-bootstrap";
import { CustomBreadcrumbs as Breadcrumbs, CustomReportsDataGrid, ReportFilter } from "../../../components";
import { dateFormater } from "../../../utils/date-formater";
import { exportDataGrid } from "devextreme/pdf_exporter";
import { exportDataGrid as ExcelExportDataGrid } from 'devextreme/excel_exporter';
import { parsedUser } from "../../../utils/GetCurrentUser";
import { saveAs } from "file-saver-es";
import { useLocation, useParams } from "react-router-dom";
import { Workbook } from "exceljs";
import jsPDF from "jspdf";
import modeljson from '../ReportDataFake/SummaryReservationByRestaurantReport/SummaryReservationByRestaurantReportModel.json';
import moment from "moment";
import PreviewModal from "../../../components/report/PreviewModal";
import React, { useCallback, useEffect, useState } from "react";
import recordsJson from '../ReportDataFake/SummaryReservationByRestaurantReport/SummaryReservationByRestaurantReportRecords.json';
import useFavorite from "../../../hooks/useFavorite";

const checkStatus = (status) => {
  const statusList = {
    R: 'Active',
    I: 'InActive',
    P: 'Pre Paid',
    C: 'Credit',
    A: 'All'
  }
  return statusList[status] ?? 'All';
}

export default function SummaryReservationByRestaurantReport() {
  // console.log(checkStatus('L'));
  // PROPERTIES
  const user = parsedUser();
  const { pathname } = useLocation();
  const dataGridRef = React.useRef(null);
  const params = useParams();

  const exportFormats = ["pdf", "excel"];
  const [isLoading, setIsLoading] = useState(false);

  const [filters] = useState(modeljson.FilterFieldSet);
  const [filterBy, setFilterBy] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [records, setRecords] = useState([]);
  const [summary, setSummary] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null);

  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite();
  // END PROPERTIES

  //METHODS
  ///BRING THE DATA FROM THE SERVER FOR RECORDS

  //TODO This method will be used to submit the filter data for the grid report.
  const generateReport = useCallback(async (obj) => {
    const ReportCriteria = filters.filter(item => item.ReportCriteria).map(item => {
      return {
        name: item.FieldTitle,
        value: obj[item.FieldName],
        fieldType: item.HtmlType
      }
    })
    setFilterBy(ReportCriteria);

    try {
      setRecords(recordsJson.Records);
      setHeaders(recordsJson.Headers);
      setSummary(recordsJson.Summary);
      setShowReport(true);
    } catch (error) {
      console.log(error);
    }
  }, [filterBy]);

  const clearReport = () => {
    try {
      setShowReport(false);
    } catch (error) {
      console.log(error);
    }
  }

  const addFavorite = async () => {
    const newFavorite = {
      icon: `${formIcon}`,
      path: pathname,
      formPath: formPath,
      title: formTitle,
    };
    UpdateFavorites(newFavorite);
  };

  const [formIcon, setFormIcon] = useState(null);
  const [formPath, setFormPath] = useState(null);
  const [formTitle, setFormTitle] = useState(modeljson.ReportTitle);

  /**
   * Checks if a value is a valid timestamp in the format "YYYY-MM-DDTHH:mm:ss".
   * @param {string} value - The value to be checked.
   * @returns {boolean} - Returns true if the value is a valid timestamp, otherwise returns false.
   */
  function isTimestamp(value) {
    const timestampRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    return typeof value === 'string' && timestampRegex.test(value) && !isNaN(Date.parse(value));
  }

  /**
   * Handles the exporting of data grid based on the specified format.
   * @param {Object} e - The event object containing the format and component information.
   * @param {string} format - The format to be exported to.
   * @returns {void}
   */
  const onExporting = useCallback((e, format) => {
    const GridInstance = e.current.instance;

    // Exporting to PDF
    if (format === "pdf") {
      const doc = new jsPDF({ orientation: "landscape", format: "letter" });
      doc.setFont("Helvetica");
      exportDataGrid({
        jsPDFDocument: doc,
        component: GridInstance,
        topLeft: { x: 1, y: 15 },
        columnWidths: "auto",
        customizeCell: function ({ gridCell, pdfCell }) {
          pdfCell.font.size = 6;
          pdfCell.font.style = "normal";
          pdfCell.wordWrapEnabled = true;
          pdfCell.textColor = "#000000";
          pdfCell.drawLeftBorder = true;
          pdfCell.drawRightBorder = true;
          if (gridCell.rowType === "header") {
            pdfCell.font.name = "Helvetica"
            pdfCell.textColor = "#FFFFFF";
            pdfCell.backgroundColor = "#525151";
          }
          if (isTimestamp(pdfCell.text) === true) {
            const formattedDate = moment(pdfCell.text).format("MM/DD/YYYY");
            pdfCell.text = formattedDate;
          }
          if (pdfCell.text === "false") {
            pdfCell.text = "No";
          } else if (pdfCell.text === "true") {
            pdfCell.text = "Yes";
          }
        },
        repeatHeaders: true,
        margin: { top: 40, left: 5, right: 5 },
      }).then(() => {
        // Header
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setFontSize(12);
          doc.setPage(i);
          const header = formTitle;
          const SubHeader = recordsJson.ReportSubTitle || " ";
          const pageWidth = doc.internal.pageSize.getWidth();
          const headerWidth = doc.getTextDimensions(header).w;
          const SubHeaderWidth = doc.getTextDimensions(SubHeader).w;
          doc.text(header, (pageWidth - headerWidth) / 2, 20);
          doc.text(SubHeader, (pageWidth - SubHeaderWidth) / 2, 26);

          //left corner
          doc.setFontSize(8);
          doc.setFont("Helvetica", "bold");
          doc.text('Report Criteria', 10, 13);
          doc.setFont("Helvetica", "normal");
          filterBy.forEach((item, index) => {
            doc.text(item.name + ':', 10, 17 + (index * 3));
            if (item.fieldType === 'date') {
              doc.text(dateFormater(item.value) ?? 'All', 50, 17 + (index * 3));
            } else if (item.fieldType === 'checkbox') {
              doc.text(item.value ? 'Yes' : 'No', 50, 17 + (index * 3));
            } else {
              // doc.text(item.value ?? 'All', 50, 17 + (index * 3));
              item.fieldType === 'radio'
                ? doc.text(checkStatus(item.value), 50, 17 + (index * 3))
                : doc.text(item.value ?? 'All', 50, 17 + (index * 3));
            }
          })

          //right corner
          const date = new moment().format("MM/DD/YYYY");
          const time = new moment().format("HH:mm");
          const newdat = date;
          const newtim = time;
          doc.setFontSize(8);
          doc.text(`Date: \t${newdat}`, 237, 13);
          doc.text(`Time: \t${newtim}`, 237, 17);
          doc.text(`Page: \t${i} of ${pageCount}`, 237, 20);
        }
        doc.save(`${formTitle}.pdf`);
      });
    }

    if (format === "preview") {
      const doc = new jsPDF({ orientation: "landscape", format: "letter" });
      doc.setFont("Helvetica");
      exportDataGrid({
        jsPDFDocument: doc,
        component: GridInstance,
        topLeft: { x: 1, y: 15 },
        columnWidths: "auto",
        customizeCell: function ({ gridCell, pdfCell }) {
          pdfCell.font.size = 6;
          pdfCell.font.style = "normal";
          pdfCell.wordWrapEnabled = true;
          pdfCell.textColor = "#000000";
          pdfCell.drawLeftBorder = true;
          pdfCell.drawRightBorder = true;
          if (gridCell.rowType === "header") {
            pdfCell.font.name = "Helvetica"
            pdfCell.textColor = "#FFFFFF";
            pdfCell.backgroundColor = "#525151";
          }
          if (isTimestamp(pdfCell.text) === true) {
            const formattedDate = moment(pdfCell.text).format("MM/DD/YYYY");
            pdfCell.text = formattedDate;
          }
          if (pdfCell.text === "false") {
            pdfCell.text = "No";
          } else if (pdfCell.text === "true") {
            pdfCell.text = "Yes";
          }
        },
        repeatHeaders: true,
        margin: { top: 40, left: 5, right: 5 },
      }).then(() => {
        // Header
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setFontSize(12);
          doc.setPage(i);
          const header = formTitle;
          const SubHeader = recordsJson.ReportSubTitle || " ";
          const pageWidth = doc.internal.pageSize.getWidth();
          const headerWidth = doc.getTextDimensions(header).w;
          const SubHeaderWidth = doc.getTextDimensions(SubHeader).w;
          doc.text(header, (pageWidth - headerWidth) / 2, 20);
          doc.text(SubHeader, (pageWidth - SubHeaderWidth) / 2, 26);

          //left corner
          doc.setFontSize(8);
          doc.setFont("Helvetica", "bold");
          doc.text('Report Criteria', 10, 13);
          doc.setFont("Helvetica", "normal");
          filterBy.forEach((item, index) => {
            doc.text(item.name + ':', 10, 17 + (index * 3));
            if (item.fieldType === 'date') {
              doc.text(dateFormater(item.value) ?? 'All', 50, 17 + (index * 3));
            } else if (item.fieldType === 'checkbox') {
              doc.text(item.value ? 'Yes' : 'No', 50, 17 + (index * 3));
            } else {
              // doc.text(item.value ?? 'All', 50, 17 + (index * 3));
              item.fieldType === 'radio'
                ? doc.text(checkStatus(item.value), 50, 17 + (index * 3))
                : doc.text(item.value ?? 'All', 50, 17 + (index * 3));
            }
          })

          //right corner
          const date = new moment().format("MM/DD/YYYY");
          const time = new moment().format("HH:mm");
          const newdat = date;
          const newtim = time;
          doc.setFontSize(8);
          doc.text(`Date: \t${newdat}`, 237, 13);
          doc.text(`Time: \t${newtim}`, 237, 17);
          doc.text(`Page: \t${i} of ${pageCount}`, 237, 20);
        }
        const output = doc.output('bloburl', { filename: `${formTitle}.pdf` });
        setBlobUrl(output);
        setShowPreviewModal(true);
      });
    }

    if (format === "excel") {
      // Exporting to EXCEL
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      ExcelExportDataGrid({
        component: GridInstance,
        worksheet,
        autoFilterEnabled: true,
        customizeCell: ({ gridCell, excelCell }) => {
          if (isTimestamp(excelCell.value) === true) {
            const formattedDate = moment(excelCell.value).format("MM/DD/YYYY");
            excelCell.value = formattedDate;
          }
          if (excelCell.value === false) {
            excelCell.value = "No";
          } else if (excelCell.value === true) {
            excelCell.value = "Yes";
          }
        },
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "DataGrid.xlsx"
          );
        });
      });
    }
  }, [filterBy]);

  // END METHODS

  //EVENTS
  useEffect(() => {
    checkIfPinned();
  }, []);

  // END EVENTS

  return (
    <React.Fragment>
      {formPath && <Breadcrumbs pathTitle={formPath} />}
      <Container>
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    <i className={formIcon + " me-3 fa-2x"}></i>
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>{formTitle}</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="w-auto d-flex align-items-center">
                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => addFavorite()}>
                      {isPinned ? "Remove from" : "Add to"} favorites
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {/* FILTERS */}
            <ReportFilter
              filterFields={filters}
              isLoading={isLoading}
              generateReport={generateReport}
              clearReport={clearReport}
            />
            {/* RECORDS */}
            {showReport &&
              <>
                <div className='mx-3 d-flex justify-content-end'>
                  <button className={"btn btn-secondary mx-2 mb-2"} onClick={() => onExporting(dataGridRef, "preview")} disabled={records.length === 0}>Preview</button>
                  <button className={"btn btn-outline-secondary mx-2 mb-2"} onClick={() => onExporting(dataGridRef, "pdf")} disabled={records.length === 0}>PDF</button>
                  <button className={'btn btn-outline-secondary mx-2 mb-2'} onClick={() => onExporting(dataGridRef, "excel")} disabled={records.length === 0}>Excel</button>
                </div>
                <CustomReportsDataGrid
                  records={records}
                  summary={summary}
                  headers={headers}
                  ref={dataGridRef}
                />
                {showPreviewModal && <PreviewModal show={true} toggle={() => setShowPreviewModal(false)} url={blobUrl} />}
              </>}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
