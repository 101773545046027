import React, { useEffect, useState } from "react";
import { Card, Modal, Row, Table } from "react-bootstrap";

import API from "../../../api/api";
import { parsedUser } from "../../../utils/GetCurrentUser";
import moment from "moment";
import { USDollar } from "../../../utils/number-to-currency";

import { useTranslation } from "react-i18next";

const RateDetails = (props) => {
  const { t } = useTranslation();

  const { show, handleClose } = props;
  const user = parsedUser();

  const [records, setRecords] = useState([]);
  console.log(props);

  const bindDataRemote = async () => {
    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=L`;
    let queryData = {
      Data: `@IdBusinessUnit=${user.IdBusinessUnit}, 
      @GUIDReservation='${props.selectedReservation.GUID}',
       @IdRoomType=${props.selectedReservation.IdRoomType},
       @IdCustomer=${props.selectedReservation.IdCustomer},
       @ArrivalDate='${props.selectedReservation.ArrivalDate}',
       @DepartureDate='${props.selectedReservation.DepartureDate}',
       @Nights=${props.selectedReservation.Nights},
       @BookingDate='${props.selectedReservation.BookingDate}'`,
    };

    let request = await API.postAction(
      `api/Reservations/RateSearch?${queryString}`,
      queryData
    );
    let results = request.data[0];
    let parsedResults = JSON.parse(results.JSONData)[0];
    let jsonData = parsedResults.JSONData;
    setRecords(jsonData);
    console.log(jsonData);
  };

  useEffect(() => {
    bindDataRemote();
  }, []);
  let totalDailyRate = 0,
    totalSupplement = 0;

  return (
    <Modal
      show={show}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
      size="xl"
      scrollable
      dialogClassName="modal-fullscreen"
      // className='modalRight z-3'
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Rate Details")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mx-2">
          <Card className="bg-light p-0">
            <Table
              hover
              size="xl"
              bordered
              className="border shadow-sm table-striped m-0"
            >
              <thead className="table-secondary">
                <tr>
                  <th>{t("Processed")}</th>
                  <th>{t("Date")}</th>
                  <th>{t("Currency")}</th>
                  <th className="text-end text-nowrap">{t("Daily Rate")}</th>
                  <th>{t("Supplement Description")}</th>
                  <th className="text-end">{t("Supplement Amount")}</th>
                  <th className="text-end text-nowrap sticky-col">
                    {t("Total")}
                  </th>
                  <th className="text-end text-nowrap">A</th>
                  <th className="text-end text-nowrap">C1</th>
                  <th className="text-end text-nowrap">C2</th>
                  <th className="text-end text-nowrap">C3</th>
                  <th className="text-end text-nowrap">{t("Extra Pax")}</th>
                  <th className="text-end text-nowrap">Adult Rate</th>
                  <th className="text-end text-nowrap">Child1 Rate</th>
                  <th className="text-end text-nowrap">Child2 Rate</th>
                  <th className="text-end text-nowrap">Child3 Rate</th>
                  <th className="text-end text-nowrap">{t("Extra Pax")} Rate</th>
                  <th className="text-end text-nowrap">{t("Room")}</th>
                  <th className="text-end text-nowrap">{t("Business Unit")}</th>
                  <th className="text-end text-nowrap">{t("Contract Code")}</th>
                  <th className="text-end text-nowrap">
                    {t("Rate Description")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {records && records.length > 0 ? (
                  records.map((item, index) => {
                    totalDailyRate += item.DailyRate;
                    totalSupplement += item.SupplementAmount;
                    return (
                      <tr key={index}>
                        <td className="text-center">
                          <input checked={item?.Invoiced} type="checkbox" />
                        </td>
                        <td className="text-end text-nowrap">
                          {item?.DateRate &&
                            moment(item.DateRate).format("MM/DD/YYYY")}
                        </td>
                        <td className="text-end text-nowrap">
                          {item?.Currency || "N/A"}
                        </td>
                        <td className="text-end text-nowrap">
                          {USDollar(item.DailyRate)}
                        </td>
                        <td className="text-end text-nowrap">
                          {item?.SupplementDescription || "-"}
                        </td>
                        <td className="text-end text-nowrap">
                          {USDollar(item?.SupplementAmount)}
                        </td>
                        <td className="text-end text-nowrap">
                          {USDollar(item?.SupplementAmount + item?.DailyRate)}
                        </td>
                        <td className="text-end text-nowrap">
                          {item?.AdultQuantity}
                        </td>
                        <td className="text-end text-nowrap">
                          {item?.Child1Quantity}
                        </td>
                        <td className="text-end text-nowrap">
                          {item?.Child2Quantity}
                        </td>
                        <td className="text-end text-nowrap">
                          {item?.Child3Quantity}
                        </td>
                        <td className="text-end text-nowrap">
                          {item?.PaxExtraQuantity}
                        </td>
                        <td className="text-end text-nowrap">
                          {USDollar(item?.AdultRate)}
                        </td>
                        <td className="text-end text-nowrap">
                          {USDollar(item?.Child1Rate)}
                        </td>
                        <td className="text-end text-nowrap">
                          {USDollar(item?.Child2Rate)}
                        </td>
                        <td className="text-end text-nowrap">
                          {USDollar(item?.Child3Rate)}
                        </td>
                        <td className="text-end text-nowrap">
                          {USDollar(item?.ExtraPaxRate)}
                        </td>
                        <td className="text-end text-nowrap">{item?.Room}</td>
                        <td className="text-end text-nowrap">
                          {item?.BusinessUnit}
                        </td>
                        <td className="text-end text-nowrap">
                          {item?.ContractCode}
                        </td>
                        <td className="text-end text-nowrap">
                          {item?.RateDescripcion}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="border-1 text-center" colSpan={16}>
                      {t("No Records Found")}
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}></td>
                  <th className="text-end">{USDollar(totalDailyRate)}</th>
                  <td></td>
                  <th className="text-end">{USDollar(totalSupplement)}</th>
                  <th className="text-end">
                    {USDollar(totalDailyRate + totalSupplement)}
                  </th>
                  <td colSpan={9}></td>
                </tr>
              </tfoot>
            </Table>
          </Card>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose(false)}>Close</button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default RateDetails;
