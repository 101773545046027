import React from 'react'
import { Container } from 'react-bootstrap'
import ReservationRestrictions from '../../components/reservation-restrictions/ReservationRestrictionsContainer'

const MasterFolioPage = () => {
  return (
    <Container>
      <ReservationRestrictions />
    </Container>
  )
}

export default MasterFolioPage
