import React, { useState } from 'react'
import { Container, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

const PreviewModal = ({
  show, toggle, url
}) => {
  const { t } = useTranslation();
  const [isLoading, setIslLoading] = useState(false);

  return (
    <Modal
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
      size={"lg"}
      dialogClassName='modal-90w'
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Report preview")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container fluid className="overflow-auto table-responsive">
          <Row>
            {url ? <iframe src={url} width="100%" height="550px"></iframe> : (
              <div
                className="text-center"
                style={{ width: "100%", height: "550px" }}
              >
                {isLoading ? (
                  <h4>{t("Loading")}...</h4>
                ) : (
                  <h4>{t("No records found")}</h4>
                )}
              </div>
            )}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default PreviewModal